@import url("https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  /* font-family: "Josefin Sans", sans-serif; */
  font-weight: 500;
  background-color: #fcfbfb;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
input[type="file"]:focus,
input[type="checkbox"]:focus textarea,
.form-control:focus,
.form-select:focus,
.uneditable-input:focus {
  border-color: #c3c3c3;
  box-shadow: none;
  outline: 0 none;
}

.flex {
  display: flex;
  padding-left: 0;
  padding-right: 0;
}

.flex-one {
  position: relative;
  flex: 0.6;
}

.image-background {
  width: 55%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url("../Assets/login-bg-main.jpg");
  background-size: cover;
  background-position: center;
  position: fixed;
  height: 100%;
}

.date-de::placeholder {
  color: #fff !important;
}

.background-image {
  width: 60%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url(../Assets/background.jpg);
  background-size: cover;
  background-position: center;
  position: fixed;
  height: 100%;
}

.head-log {
  font-weight: 600;
}

.logo-image {
  width: 230px;
  padding-top: 80px;
  /* margin-left: 30px; */
}

.flex-two {
  flex: 0.4;
}

.logged {
  padding-top: 100px;
  width: 90%;
}

.review-input {
  width: 90%;
  padding: 5px 10px;
  outline: none;
  border: none;
  background-color: transparent;
  box-shadow: 0 0 0 transparent !important;
  transition: all 0.2s ease-in-out;
}

.review-card {
  background-color: #fff !important;
  border-color: #cacaca;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 13px !important;
  transition: all 0.2s ease-in-out;
}

.review-group {
  position: relative;
  display: flex;
  border: 1px solid #cbcbcb;
  width: 100%;
  height: 45px;
  border-radius: 5px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
}

.review-group.error {
  border-color: #fb1100 !important;
}

.review-input::-webkit-input-placeholder {
  color: #d5d5d5;
  font-weight: 300 !important;
}

.review-group > i {
  padding: 3px 16px;
  height: auto;
  font-size: 20px;
  color: #cbcbcb;
}

.review-label {
  color: #909090;
  font-size: 15px;

  margin: 0;
}
.hamburger-icon {
  margin-left: 10px;
}
.review-input:focus + .inp_ico {
  color: #f6695f;
  /* border: 1px solid #f44336 !important; */
}

.form-box {
  padding-top: 50px;
}

.head-log {
  font-size: 20px;
  margin-bottom: 30px;
}
.top-bar {
  position: relative;
  width: 100%;
  height: 60px;
  z-index: 99;
  background-color: #eee;
  top: 0;
  left: 0;
  padding: 10px 0;
  transition: all 0.2s ease-in-out;
}
.btn-grps {
  display: flex;
}
.download-btn {
  position: absolute;
  border: none;
  outline: none;
  padding: 8px 16px;
  background-color: #c62429;
  right: 0;
  border-radius: 5px;
  color: #fff;
  transition: all 0.2s ease-in-out;

  i {
    font-size: 20px !important;
    line-height: 0px;
  }
}
.download-btn:hover {
  background-color: #a22722;
}
.download-btn:disabled {
  background-color: #e3a0a2 !important;
  cursor: default;
}
.send_btn {
  background-color: #ec2027;
  border: 1px solid transparent;
  color: #fff;
  padding-left: 10px;
  padding-left: 33px;
  padding-right: 33px;
  border-radius: 4px;
}
.close_btnn {
  position: absolute;
  border: none;
  outline: none;
  background-color: transparent;
  right: 10px;
  top: 10px;
}
.submit_btnn {
  display: block;
  border: none;
  outline: none;
  background-color: #ec2027;
  color: #fff;
  padding: 7px 25px;
  margin: 0 auto;
  margin-top: 20px;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}
.table_paddign {
  padding-left: 94px;
  padding-right: 85px;
  padding-top: 28px;
}
.table_color {
  border-color: #c3c3c3;
}
.send-btn-contract {
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  color: #fff;
  outline: none;
  padding: 7px 20px;
  position: absolute;
  right: 70px;
  transition: all 0.2s ease-in-out;
}
.send-btn-contract:hover {
  background-color: #29619d;
}
.send-btn-contract:disabled {
  background-color: #8298a1;
  cursor: default;
}
.upload-btn {
  position: absolute;
  border: none;
  outline: none;
  padding: 7px 20px;
  background-color: #118f4a;
  right: 195px;
  border-radius: 5px;
  color: #fff;
  transition: all 0.2s ease-in-out;
}
.upload-btn:hover {
  background-color: #155724;
}
.upload-btn:disabled {
  background-color: #8298a1;
  cursor: default;
}
.group-sub {
  background: linear-gradient(
    93deg,
    rgba(243, 113, 60, 1) 0%,
    rgba(229, 71, 79, 1) 100%
  );
  font-size: 16px;
  border: none;
  padding: 10px;
  color: #fff;
  border-radius: 4px;
  width: 100px;
}

.fp-button {
  position: relative;
  width: 100%;
  height: 45px;
  border: none;
  outline: none;
  margin-top: 10px;
  font-size: 18px;
  letter-spacing: 0.04rem;
  font-weight: 500;
  color: #fff;
  /* background: rgb(243, 113, 60);
  background: linear-gradient(
    93deg,
    rgba(243, 113, 60, 1) 0%,
    rgba(229, 71, 79, 1) 100%
  ); */
  background-color: #af2429;
  border-radius: 5px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;

  label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.fp-button:disabled {
  background: rgb(243, 113, 60);
  background: linear-gradient(
    93deg,
    rgb(255, 144, 101) 0%,
    rgb(255, 90, 98) 100%
  );
}

button,
a {
  &.btn {
    &.btn-primary {
      background: linear-gradient(270deg, #e54750 0%, #f3723b 100%);
      border-color: #e54750 !important;
    }

    &.btn-outline-primary {
      border-color: #e54750 !important;
      color: #000000;

      &:hover {
        background-color: #fff;
      }
    }
  }
}

body {
  position: relative;
  min-height: 100%;
}

.para {
  position: relative;
  top: 4px;
  margin: 0;
}

a {
  transition: background 0.2s, color 0.2s;
}

a:hover,
a:focus {
  text-decoration: none;
}

#wrapper {
  padding-top: 70px;
  padding-left: 0;
  transition: all 0.5s ease;
  position: relative;
}

#sidebar-wrapper {
  z-index: 1000;
  position: fixed;
  left: 250px;
  overflow-y: scroll;
  width: 0;
  overflow-x: hidden;
  height: 100%;
  bottom: -3px;
  padding-bottom: 20px !important;
  border-right: 1px solid #fff;
  margin-left: -250px;
  transition: all 0.5s ease;
  background: #fff;
  z-index: 10 !important;
}

#wrapper.toggled #sidebar-wrapper {
  width: 250px;
}

.sidebar-brand {
  position: absolute;
  top: 0;
  width: 250px;
  text-align: center;
  padding: 20px 0;
}

.sidebar-brand h2 {
  margin: 0;
  font-weight: 600;
  font-size: 24px;
  color: #fff;
}

.sidebar-nav {
  position: absolute;
  top: 65px;
  width: 250px;
  padding-left: 0;
  list-style: none;
  overflow: hidden;
}

.sidebar-nav > li {
  text-indent: 10px;
  line-height: 42px;
}

.sidebar-nav > ul {
  margin-bottom: 20px !important;
}

.dash-nav-user i {
  font-size: 22px;
  padding-right: 100px;
}

.sidebar-nav > li i {
  font-size: 20px;
  font-weight: 700;
}

.heads {
  text-indent: 5px;
  font-family: "Josefin Sans", sans-serif;
}

.sidebar-nav > li a {
  display: block;
  text-decoration: none;
  color: gray;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  gap: 10px;
}

.sidebar-nav > li.active > a {
  text-decoration: none;
  color: #000;
  background-color: #a7a7a7;
}

.sidebar-nav > li.active > a:hover {
  color: #000 !important;
}

.sidebar-nav li a:hover {
  color: #252525 !important;
}

#navbar-wrapper {
  width: 100%;
  height: 68px;
  left: 0;
  position: fixed !important;
  z-index: 2;
  border-bottom: 1px solid #dee2e6;
  /* overflow: hidden; */
}

#wrapper.toggled #navbar-wrapper {
  position: absolute;
  margin-right: -250px;
}

#navbar-wrapper .navbar {
  border-width: 0 0 0 0;
  font-size: 24px;
  margin-bottom: 0;
  border-radius: 0;
}

#navbar-wrapper .navbar a {
  color: #757575;
}

#navbar-wrapper .navbar a:hover {
  color: gray;
}

#content-wrapper {
  width: 100%;
  position: absolute;
  background-color: #fff;
  top: 100px;
}

#wrapper.toggled #content-wrapper {
  position: absolute;
  margin-right: -250px;
}

@media (min-width: 992px) {
  #wrapper {
    padding-left: 250px;
  }

  #wrapper.toggled {
    padding-left: 60px;

    .main-head {
      display: none;
    }

    .para {
      display: none !important;
    }

    #sidebar-wrapper li {
      list-style: none;
      width: 60px;
      text-decoration: none;
    }
  }

  #sidebar-wrapper {
    width: 250px;
  }

  #wrapper.toggled #sidebar-wrapper {
    width: 60px;
  }

  #wrapper.toggled #navbar-wrapper {
    position: absolute;
    margin-right: -190px;
  }

  #wrapper.toggled #content-wrapper {
    position: absolute;
    margin-right: -190px;
  }

  #navbar-wrapper {
    position: fixed;
  }

  #wrapper.toggled {
    padding-left: 60px;
  }

  #content-wrapper {
    position: relative;
    top: 0;
  }

  #wrapper.toggled #navbar-wrapper,
  #wrapper.toggled #content-wrapper {
    position: relative;
    margin-right: 60px;
  }
}

@media (max-width: 991px) {
  .table-mgt {
    position: absolute;
    padding: 5px 20px !important;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    background-color: #fff;
    text-decoration: none;
    font-size: 14px;
    list-style: none;
    border-radius: 4px;
    z-index: 99;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
  }

  #wrapper {
    padding-left: 30px;
  }

  #sidebar-wrapper {
    width: 60px;
  }

  #wrapper.toggled #navbar-wrapper {
    position: absolute;
    margin-right: -250px;
  }

  #wrapper.toggled #content-wrapper {
    position: absolute;
    margin-right: -250px;
  }

  #navbar-wrapper {
    position: relative;
  }

  #wrapper.toggled {
    padding-left: 250px;
  }

  #content-wrapper {
    position: relative;
    top: 0;
  }

  #wrapper.toggled #navbar-wrapper,
  #wrapper.toggled #content-wrapper {
    position: relative;
    margin-right: 250px;
  }
}

.dashboard-navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  gap: 10px;
  isolation: isolate;
  background: #ffffff;
  z-index: 999 !important;
}

.dashboard-content-wrapper {
  padding: 1rem 0;

  .content-title {
    font-size: 24px;
  }
}

.logo {
  width: 150px;
}

.dashboard-card {
  overflow: hidden;
  width: 100%;
  background-color: #fff;
}

.dc-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 0;
}

.dc-title {
  font-size: 1.2rem;
  font-weight: 600;
}

.dash-nav-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 1rem;
}

.dc-content {
  padding: 1rem 0.5rem 0.5rem;
}

/* -----side profile--------- */
.profilename {
  position: absolute;
  right: 5px;
  top: 50px;
  background-color: #fff;
  padding: 15px;
  border: 1px solid rgb(248, 240, 240);
  border-radius: 4px;
  width: 180px;
}

.pro-name {
  font-size: 10px;
  font-weight: 600;
  text-align: center;
}

.submit_btn {
  padding: 7px 20px;
  background-color: #f44336;
  color: #fff;
  font-weight: 500;
  border-radius: 5px;
  border: none;
  outline: none;
  margin-top: 10px;
  transition: all 0.2s ease-in-out;
}

.submit_btn:hover {
  background-color: #ae3027;
}

.submit_btn:disabled {
  background-color: #ba8f8c;
}

.review-input::placeholder {
  color: rgb(39, 39, 39) !important;
}

.edit-pen i {
  float: right !important;
  color: #fb4336;
  position: relative;
  cursor: pointer;
  right: 20px;
}

.reset-wr {
  color: #fb4336;
  cursor: pointer;
  border: none;
  outline: none;
  font-size: 13px;
  position: relative;
  left: -5px;
  background-color: transparent;
}

.edit_btn {
  position: absolute;
  color: #f44336;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  right: 10px;
  top: 10px;
}

.profile_data {
  font-weight: 500;
  font-size: 15px;
  color: #747474;
  user-select: none;
}

.logout {
  font-size: 12px;
}

.action_btn {
  position: relative;
  outline: none;
  width: 70px;
  padding: 3px 0;
  border-radius: 6px;
  background-color: transparent;
  border: none;
  color: #f44336;
  font-size: 13px;
  transition: all 0.2s ease-in-out;
}

.action_btn:hover {
  background-color: #f44336;
  color: #fff;
}

.pro-btn {
  border: 1px solid #ec2027;
  color: #ec2027;
  background-color: transparent;
  border-radius: 4px;
  padding: 5px;
  font-size: 12px;
  height: 27px;
  font-weight: 600;
  margin: 5px;
}

.pro-role {
  color: #ec2027;
  font-size: 14px;
  text-align: center;
  font-weight: 600;
}
.arrow_icon {
  border: 2px solid black;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 66px;
  left: 41px;
  cursor: pointer;
  transition: left 0.5s ease;
}
.toggle-arrow {
  margin-left: 4px;
  margin-top: -30px;
}

.arrow_icon.toggled {
  left: 206px;
}

.log {
  font-weight: 700 !important;
  color: gray;
}

.flexed {
  display: flex;
  align-items: center;
}

.one-box {
  display: flex;
  align-items: center;
}

#logo_wrapper {
  height: 100px;
  overflow: hidden;
}

.two-box {
  position: absolute;
  right: 20px;
}

.navbar-header {
  font-size: 15px !important;
  padding: 5px;
  /* background: rgb(243, 113, 60);
  background: linear-gradient(
    93deg,
    rgba(243, 113, 60, 1) 0%,
    rgba(229, 71, 79, 1) 100%
  ); */
  /* background-color: #000; */
  /* color: white; */
  margin-left: 62px;
  margin-top: 102px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-header i {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 22px;
}

.icons-dashbar i {
  font-size: 18px;
  padding-right: 20px;
}

.icons-dashbar {
  margin-right: 50px;
}

.search-box {
  position: relative;
  display: flex;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.search-input {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  background-color: #f1f1f1;
  color: #6c6c6c;
  margin-left: 30px;
  height: 30px;
  padding-left: 20px;
  border-radius: 25px;
  border: none;
  transition: all 0.4s;
}

.search-input:focus {
  border: none;
  outline: none;
  box-shadow: 0 1px 20px #fafafa;
}

.search-btn {
  background-color: transparent;
  font-size: 18px;
  padding-top: -5px;
  margin-left: -45px;
  border: none;
  color: #6c6c6c;
  transition: all 0.4s;
  z-index: 10;
}

/* .text-center {
  font-family: "Josefin Sans", sans-serif;
} */
.search-btn:hover {
  transform: scale(1.2);
  cursor: pointer;
  color: black;
}

.search-btn:focus {
  outline: none;
  color: black;
}

.head-h4 {
  font-weight: 600;
  font-family: "Josefin Sans", sans-serif;
  font-size: 18px;
}

.head-h6 {
  font-weight: 600;
  font-family: "Josefin Sans", sans-serif;
  font-size: 35px;
}

.head-h5 {
  font-weight: 600;
  /* font-family: "Josefin Sans", sans-serif; */
  font-size: 30px;
}

.table i {
  color: rgb(226, 86, 51);
  font-weight: 700 !important;
  cursor: pointer;
  font-size: 19px;
}

.table a {
  color: rgb(226, 86, 51);
  font-weight: 700 !important;
  cursor: pointer;
  font-size: 19px;
}

.sidebar-nav li.active {
  color: #cf302b;
}

/* .dash-widgetimg {
  border-radius: 50%;
  height: 50px;
  width: 50px;
} */
.dash-widgetimg {
  border-radius: 50%;
  height: 75px;
  width: 75px;
}
.dash_img {
  transition: all 0.3s ease-in-out;
  margin: 0 auto;
  display: block;
}
.dash_img:hover {
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  z-index: 0;
}
.total {
  font-size: 20px;
}

/* -----------dummy---------------- */
.admin-img {
  margin: 0px auto;
  width: 50%;
}

.admin_img {
  width: 100%;
  height: 100%;
}

.dash-count {
  background: #af2429;
  color: #fff;
  min-height: 98px;
  width: 100%;
  border-radius: 6px;
  margin: 0 0 25px;
  padding: 20px;
}

.dash-count.das1 {
  background: #747474;
}

.dash-count.das2 {
  background: #e54750;
}

.dash-count.das3 {
  background: #dd4430;
}

.dash-count h4 {
  /* font-size: 24px; */
  font-size: 29px;
  font-weight: 700;
  margin-bottom: 0;
  color: #fff;
}
.dash-img-res {
  overflow: hidden;
  /* padding: 0; */
  width: 70px;
  height: 70px;
  /* border:1px solid #000; */
  /* float:left;
    margin:10px; */
  border-radius: 50%;
}
/* .admin_card{
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.admin_card:hover {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
} */
.dash-count h5 {
  /* font-size: 14px; */
  font-size: 17px;
  color: #fff;
}

.dash-count .dash-imgs {
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.dash_icons {
  display: inline-block;
  width: 70px;
  /* Width and height can be adjusted */
  height: 70px;
  border-radius: 50%;
  border: 2px solid transparent;
  /* Border color and thickness */
  text-align: center;
  line-height: 58px;
  /* Center icon vertically */
  font-size: 35px;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.dash_icons:hover {
  transform: scale(1.25);
  -webkit-transform: scale(1.25);
  -ms-transform: scale(1.25);
}

.dash-count .dash-imgs img {
  width: 20px;
  height: 20px;
}

.dash-count .dash-imgs i {
  font-size: 30px;
  color: #fff;
}

.dash-count:hover .dash-imgs {
  transform: scale(1.25);
  -webkit-transform: scale(1.25);
  -ms-transform: scale(1.25);
}

.btn-white.dropdown-toggle:after {
  display: none;
}

#sidebar-wrapper li {
  list-style: none;
  text-decoration: none;
}

#sidebar-wrapper li i {
  font-size: 20px;
  padding-left: 10px;
}

.main-head {
  font-family: "Josefin Sans", sans-serif;
  padding-top: 10px !important;
  margin-bottom: 0px !important;
  font-size: 14px;
  margin-top: 10px;
  margin-left: 5px;
  padding-bottom: 0px !important;
}

.details-card {
  padding: 10px;
  display: flex;
}

.dash-widgetimg {
  flex: 0.2;
}

.dash-widgetcontent {
  flex: 0.6;
}

.fp-add {
  /* background: linear-gradient(
    93deg,
    rgba(243, 113, 60, 1) 0%,
    rgba(229, 71, 79, 1) 100%
  ); */
  background-color: #000;
  border: none;
  padding: 5px;
  border-radius: 5px;
  float: right;
  width: 100px;
  bottom: 10px;
  position: relative;
  right: 20px;
  color: white;
}

.selected-products {
  height: 43px;
  position: relative;
  width: 100%;
  top: 3px;
  padding: 5px;
  border: 1px solid rgb(181 176 176);
  border-radius: 4px;
}

.selected-products :focus {
  border: none;
}

option {
  padding: 5px;
  margin-top: 5px;
}

.button-div {
  width: 20%;
  margin-top: 30px;
}

.error_ {
  color: #f01e17;
  font-weight: 500;
  font-size: 13px;
}

@media (max-width: 991px) {
  #wrapper.toggled #sidebar-wrapper {
    width: 60px;
  }

  #sidebar-wrapper {
    .main-head {
      display: none;
    }
  }

  #wrapper.toggled {
    padding-left: 20px;
  }

  .navbar-header {
    display: none;
  }
  .arrow_icon.toggled {
    display: none;
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c5c4b9;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.badges {
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
  padding: 10px 10px;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  min-width: 90px;
}

.icon {
  width: 30px;
  height: 30px;
  color: #ec2027;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 800 !important;
  /* background-color: rgb(238, 195, 189); */
  font-size: 16px;
  margin: 5px;
}

.icons-list img {
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.icons-list {
  display: flex;
  justify-content: flex-end;
  right: 17px;
  position: relative;
}

.product-profile {
  width: 200px;
  padding: 10px;
  height: 100%;
}

.banner-profile {
  background-color: #c5c4b9;
  width: 200px;
  height: 150px;
  margin: 0px auto;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.float-right {
  float: right;
  position: relative;
  right: 40px;
  top: -9px;
  height: 35px;
}

.head-plan {
  padding: 5px;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
}

.product-name {
  font-weight: 600;
  font-size: 18px;
}

.tick-icon {
  padding-right: 10px;
  font-size: 20px;
  color: #cf302b;
}

.fp-card {
  position: relative;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #dbdde0b3;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  background-color: #fff;
  font-size: 0.9rem;
}

.pricing_features {
  color: gray;
}

.features {
  list-style: none;
  color: gray;
  padding: 0 !important;
}

.features i {
  padding-right: 5px;
  padding-top: 5px;
  font-size: 20px;
}

.prices {
  position: relative;

  .row {
    position: relative;
    z-index: 1;
  }

  .col {
    position: relative;
    z-index: 2;
  }

  .days {
    font-size: 15px;
    font-weight: 600 !important;
    color: #747474;
  }

  b {
    font-weight: 600 !important;
    color: #252525;
    font-size: 22px;
  }
}

.prices::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1px;
  height: 70%;
  background-color: #747474;
}

.log-sym {
  padding-right: 2px !important;
}

.multiselect {
  width: 200px;
}

.selectBox {
  position: relative;
}

.selectBox select {
  width: 100%;
  font-weight: bold;
}

#checkboxes label {
  display: block;
}

#specialCheckboxes {
  display: block !important;
}

.select-op {
  cursor: pointer;
}

/* backbutton */
div.backBtn {
  width: 100px;
  background-color: #f4f4f4;
  transition: all 0.4s ease;
  position: relative;
  cursor: pointer;
}

span.line {
  bottom: auto;
  right: auto;
  top: auto;
  left: auto;
  background-color: #333;
  border-radius: 10px;
  width: 100%;
  left: 0px;
  height: 2px;
  display: block;
  position: absolute;
  transition: width 0.2s ease 0.1s, left 0.2s ease, transform 0.2s ease 0.3s,
    background-color 0.2s ease;
}

span.tLine {
  top: 0px;
}

span.mLine {
  top: 13px;
  opacity: 0;
}

span.bLine {
  top: 26px;
}

.label {
  position: absolute;
  left: 0px;
  top: 5px;
  width: 100%;
  text-align: center;
  transition: all 0.4s ease;
  font-size: 1em;
}

div.backBtn:hover span.label {
  left: 25px;
}

div.backBtn:hover span.line {
  left: -10px;
  height: 5px;
  background-color: #f76060;
}

div.backBtn:hover span.tLine {
  width: 25px;
  transform: rotate(-45deg);
  left: -15px;
  top: 6px;
}

div.backBtn:hover span.mLine {
  opacity: 1;
  width: 30px;
}

div.backBtn:hover span.bLine {
  width: 25px;
  transform: rotate(45deg);
  left: -15px;
  top: 20px;
}

/* endbackbutton */
.add-button {
  padding: 5px;

  background: linear-gradient(
    93deg,
    rgba(243, 113, 60, 1) 0%,
    rgba(229, 71, 79, 1) 100%
  );
  color: white;
  border: none;
  border-radius: 6px;
  width: 100px;
}

.delete-button {
  padding: 5px;
  background-color: #cf302b;
  color: white;
  border: none;
  border-radius: 6px;
  width: 75px;
}

/* li:last-child {
  padding-bottom: 10px;
  margin-bottom: 10px;
} */
.input-set {
  color: #cf302b;
  border: 1px solid #cf302b;
  background-color: transparent;
  border-radius: 4px;
  margin-top: 10px;
}

.list-check {
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 5px;
  padding: 15px;
}

.list-checked {
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 5px;
  padding: 15px;
}

.des_ {
  margin: 10px;
  text-align: justify;
  padding: 10px;
  overflow: hidden;
  width: 95%;
}

.support-service {
  list-style: none;
}

.support-service li i {
  padding: 2px;
  padding-bottom: 2px;
}

.support-service li {
  padding: 5px;
  padding-bottom: 8px;
}

h6 span {
  font-weight: 400;
  padding-left: 10px;
  letter-spacing: 1px;
  text-align: justify;
  font-size: 14px;
}

.feature-des {
  font-size: 14px;
  color: #252525;
  margin: 0;
  padding: 15px;
}

.main-feature {
  color: gray;
  margin: 0;
  font-size: 14px;
  padding: 15px;
}

.feature_bold {
  padding: 15px;
  font-weight: 500;
}

.box {
  width: 80%;
  margin: 0px auto;
}

.css-13cymwt-control {
  height: 45px;
  border: 1px solid #c3c3c3 !important;
}

.table a {
  font-weight: 700 !important;
  cursor: pointer;
  padding: 5px;
  padding-left: 15px;
  width: 50px;
  font-size: 14px;
  color: black;
}

/* ------------details-card---------- */

/*  */

.payment.mastercard {
  width: 577px;
  height: auto;
  border-radius: 10px;
}

.header {
  text-align: left;
  background: #ffb340;
  color: rgb(255, 255, 255);
  font-size: 18px !important;
  padding: 20px;
  padding-left: 55px;
  text-transform: uppercase;
}

.new-header {
  text-align: left;
  background: #f1dfa0;
  color: rgb(0, 0, 0);
  font-size: 18px !important;
  padding: 10px;
  padding-left: 55px;
  text-transform: uppercase;
}

.red-header {
  text-align: left;
  background: #f7cdcd;
  color: rgb(61, 61, 61);
  font-size: 18px !important;
  padding: 10px;
  padding-left: 30px;
  text-transform: uppercase;
}

.admin-data {
  padding: 10px;
  padding-bottom: 0 !important;
}

.form_sub {
  font-size: 14px;
  font-weight: 500;
  margin: 10px 0;
}

.css-t3ipsp-control {
  border-color: #c3c3c3 !important;
  box-shadow: 0 0 0 transparent !important;
}

.btn-toggle {
  display: flex;
  position: relative;
  margin: 0px auto;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.one-btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background: #747474;
  border: none;
  color: #fff;
  padding: 5px;
  font-size: 12px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 0px;
  transition: all 0.2s ease-in-out;
  width: 55px !important;
  height: 38px !important;
}

.one-btn.btn-full {
  width: 60%;
  border-radius: 3px;
}

.two-btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background: #747474;
  border: none;
  color: #fff;
  padding: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  font-size: 12px;
  border-bottom-right-radius: 5px;
  height: 38px !important;
}

.two-btn:hover {
  background-color: #585858;
}

.one-btn:hover {
  background-color: #585858;
}

.unnamed-list {
  list-style: none;
}

.table-mgt {
  position: absolute;
  width: auto;
  padding: 0px 20px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  background-color: #fff;
  text-decoration: none;
  font-size: 14px;
  list-style: none;
  border-radius: 4px;
  z-index: 99;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
}

.table-mgt .btn {
  padding: 5px 0px;
  border: none;
  font-size: 12px;
  white-space: nowrap;
}

.table-mgt li {
  white-space: nowrap;
}

.table-mgt li:last-child {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.table-mgt > li:hover {
  color: #dd4430;
}

.card-image {
  width: 100%;
  padding: 10px;
  height: 100%;
}

.assign {
  padding-bottom: 30px;
}

.save-btn {
  background: rgb(243, 113, 60);
  background: linear-gradient(
    93deg,
    rgba(243, 113, 60, 1) 0%,
    rgba(229, 71, 79, 1) 100%
  );
  border-radius: 5px;
  outline: none;
  color: white;
  padding: 5px;
  overflow: hidden;
  border: none;
  transition: all 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.boxed {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.check-form {
  height: 43px;
  position: relative;
  width: 400px;
  top: 3px;
  padding: 5px;
  border: 1px solid rgb(181 176 176);
  border-radius: 4px;
}

.stage-label {
  font-weight: 600;
  padding-left: 10px;
}

.form-group {
  margin-right: 20px;
}

.image-row {
  display: inline !important;
}

.product-images {
  position: relative;
  display: flex;
}

.image-size {
  width: 140px;
}

.images-div {
  height: 400px;
  overflow-y: scroll !important;
  overflow-x: hidden;
}

.image-profile {
  margin: 0px auto;
  width: 200px;
  height: 150px;
  overflow: hidden;
  background-image: contain;
}

.merchant-badge {
  cursor: pointer;
}

.box1 {
  border-radius: 5px;
  height: 100px;
  margin: 10px;
  background-color: #e0ebf2;
  padding: 10px;
}

.box2 {
  border-radius: 5px;
  height: 100px;
  margin: 10px;
  background-color: #cfcbc9;
  padding: 10px;
}

.box3 {
  height: 100px;
  border-radius: 5px;
  background-color: #d3e1df;
  padding: 10px;
  margin: 10px;
}

.box4 {
  height: 100px;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  background-color: #e1dacc;
}

.box5 {
  height: 100px;
  border-radius: 5px;
  margin: 10px;
  padding: 10px;
  background-color: #d6d7dc;
}

.main-box {
  height: 100%;
  margin: 10px;
  padding: 10px;
}

/* ------graph start------------- */

/* --------graph end------------------ */

/* --------Privilege Modal------------------ */
.privilege_modal_wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #00000032;
  left: 0;
  top: 0;
  z-index: 9999;
  transition: all 0.2s ease-in-out;
}

.add_card {
  position: absolute;
  width: 50%;
  height: auto;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #79797979;
  padding: 20px;
  left: 50%;
  top: 30%;
  transform: translate(-50%, -50%);
}

.add_privilege {
  width: 30%;
  margin: 0 auto;
}

.close_btn {
  position: absolute;
  border: none;
  background-color: transparent;
  color: #555;
  right: 20px;
  top: 20px;
}

.review-group > .fp-select {
  width: 100%;
  border: none;
  outline: none;
  padding: 0 10px;
}

.fp-select:focus + .inp_ico {
  color: #ec2027;
}

.css-t3ipsp-control,
.css-13cymwt-control {
  height: 45px;
  /* width: 100% !important;
  border: none !important; */
  overflow-y: scroll;
}

.des-ser {
  padding: 5px;
  margin: 0;
  list-style: none;
}

.head-des {
  padding: 0 !important;
  margin: 0 !important;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.align-right {
  float: right;
}

.stage-btn {
  background: rgb(243, 113, 60);
  background: linear-gradient(
    93deg,
    rgba(243, 113, 60, 1) 0%,
    rgba(229, 71, 79, 1) 100%
  );

  border: none;
  color: white;
  border-radius: 4px;
}

.pro-named {
  color: #cf302b;
  font-size: 18px;
  text-align: center;
}

.card-pro {
  width: 150px;
}

.feature_bolded {
  border: 1px solid rgb(237 228 228);
  padding: 14px;
  margin: 10px;
  margin-top: 15px;
}

/* ---pagination=--- */

.pagination {
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  padding: 10px;
}

.pagination .previous .page-link {
  /* background: linear-gradient(
    93deg,
    rgba(243, 113, 60, 1) 0%,
    rgba(229, 71, 79, 1) 100%
  ); */
  background-color: transparent;
  color: #252525;
  height: 35px;
  width: 35px;
  border-radius: 50% !important;
  font-size: 20px;
  border: none;
  outline: none;
}

/* Styles for the next arrow */
.pagination .previous .page-link {
  /* background: linear-gradient(
    93deg,
    rgba(243, 113, 60, 1) 0%,
    rgba(229, 71, 79, 1) 100%
  ); */
  background-color: transparent;
  color: #252525;
  width: 35px;
  border-radius: 50% !important;
  height: 35px;
  font-size: 16px;
}

.pagination .next .page-link {
  /* background: linear-gradient(
    93deg,
    rgba(243, 113, 60, 1) 0%,
    rgba(229, 71, 79, 1) 100%
  ); */
  background-color: transparent;
  color: #252525;
  width: 33px;
  height: 33px;
  /* font-size: 20px; */
  border-radius: 50%;
  border: none;
  outline: none;
}

.page-link:focus {
  box-shadow: 0 0 transparent !important;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.text {
  color: gray;
  padding-bottom: 3px;
}

.pagination li {
  display: inline-block;
  margin: 0 5px;
}

.pagination a {
  display: block;
  padding: 2px 7px;
  text-decoration: none;
  color: #000;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.pagination .paging__link--active {
  /* border: 1px solid rgb(243, 113, 60);
  color: rgb(243, 113, 60); */
  background-color: #252525;
  color: #fff;
  border-radius: 50%;
  /* font-weight: bold; */
  /* border-radius: 5px; */
  overflow: hidden;
  outline: none;
  padding: 5px 7px;
}

.pagination .paging__link--active > a {
  border: none !important;
}

.pagination .ellipsis {
  margin: 0 5px;
}

.close-btn {
  background-color: rgb(243, 191, 191);
  color: rgb(148, 8, 8);
  border-radius: 50%;
  text-align: center;
  font-weight: 700;
  font-size: 12px;
  border: none;
}

.form-check {
  padding-left: 20px;
}

/* dashboard */
#content-wrapper {
  background-color: #fcfbfb;
  font-size: 14px;
}

.dashboard-card {
  overflow: hidden;
}

.details-en {
  list-style: none;
}

.profile-img {
  width: 100%;
  border-radius: 4px;
}

.fourth {
  border: 1px solid #f8434a;
  border-radius: 4px;
  padding: 5px;
  width: 150px;
}

.fourth:hover {
  background-color: rgb(248, 187, 187);
  color: #9b2d29;
  border: none;
}

.know_more_btn {
  position: absolute;
  border: none;
  outline: none;
  margin: auto;
  padding: 10px 0;
  background-color: transparent;
  transition: all 0.2s ease;
  bottom: 20px;
}

.know_more_btn span {
  position: relative;
  font-size: 13px;
  line-height: 1rem;
  font-weight: 500;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  vertical-align: middle;
  z-index: 12;
}

.know_more_btn::before {
  content: "";
  position: absolute;
  display: block;
  border-radius: 28px;
  background: #eec7c9;
  width: 35px;
  height: 35px;
  top: 4px;
  transition: all 0.3s ease;
}

.know_more_btn i {
  position: relative;
  top: 0;
  margin-left: 10px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2;
  transform: translateX(-5px);
  transition: all 0.3s ease;
}

.know_more_btn:hover {
  width: 150px;
  font-weight: 700;
  background: #eec7c9;
}

.settings {
  cursor: pointer;
  /* margin-bottom: 20px !important; */
}

i {
  transform: translateX(0);
}

.phone-input .special-label {
  display: none;
}

.phone-input > .form-control {
  height: 100%;
  border: none;
}

.phone-input > .form-control:focus {
  box-shadow: 0 0 transparent;
}

#phone_ico {
  position: absolute;
  right: 10px;
}

.icon-set {
  font-size: 15px;
  justify-content: space-between;
  padding-right: 19px;
  float: right;
}

.form-control:focus + .inp_ico {
  color: #dd4430;
}

.fp-button.ad_btn {
  width: 200px;
  margin: 0 auto;
}

.add-exe {
  border: none;
  padding: 7px;
  background-color: #f0685e;
  color: #ffffff;
  font-size: 12px;
  border-radius: 4px;
  font-weight: 700;
}

.nav-item > .mob-label {
  display: none;
}

@media screen and (min-width: 786px) {
  .request-data {
    width: 90%;
  }

  .flo_rig {
    float: right !important;
    position: relative;
    top: 10px;
    left: 0%;
    height: 35px;
    align-items: right;
  }

  .pro_add {
    border: none;
    background-color: #c5f3f5;
    color: #155759;
    border-radius: 4px;
    padding: 7px;
    position: relative;
    font-weight: 500;
  }

  .comp_proposal_stage {
    background-color: rgb(255, 216, 216);
    color: red;
    padding: 5px;
    font-weight: 500;
    border-radius: 4px;
    border: none;
  }
}

@media screen and (max-width: 786px) {
  #sidebar-wrapper:hover {
    width: 250px;
  }

  .footer-box {
    height: auto;
  }

  .flo_rig {
    float: right !important;
    position: relative;
    top: 10px;
    left: 0%;
    height: 35px;
    align-items: right;
  }

  .overflow {
    overflow-x: scroll;
    overflow-y: none;
  }
}

.listed-navbar {
  visibility: hidden;
}

@media screen and (max-width: 500px) {
  .flo_rig {
    float: right !important;
    position: relative;
    top: 0;
    left: 0 !important;
    height: 35px;
    align-items: right;
  }

  .footer-box {
    height: auto;
  }

  .data-rep {
    overflow: scroll !important;
  }

  .listed-navbar {
    visibility: unset;
  }

  .head-dt {
    font-size: 14px;
    color: rgb(110, 110, 110);
    text-align: left !important;
    position: relative;
    top: 10px;
  }

  .flexed {
    visibility: hidden;
  }

  .small-logo {
    width: 100px;
    left: -160px;
    top: 10px;
    position: relative;
  }

  .toggle-side {
    top: -15px;
    position: relative;
  }
}

.not_assigned_card {
  padding: 10px;
}

.not_assigned {
  color: #7777;
  text-align: center;
  user-select: none;
}

.assign-btn {
  /* display: block; */
  width: 200px;
  font-size: 15px;
  margin: 0 auto;
  text-align: center;
}

.loader_ {
  display: block;
  margin: 0 auto;
}

.one-btn.view_enquiry_btn {
  width: 100% !important;
  border-radius: 5px;
}

.product_card_ {
  position: relative;
  padding: 15px 10px;
  overflow: hidden;
}

.price_details {
  display: flex;
  position: relative;
}
.side-show {
  display: none;
}
.price_details > .price_ {
  position: relative;
  /* border: 1px solid #ec2027; */
  padding: 5px 10px;
  border-radius: 16px;
  cursor: pointer;
  color: #ec2027;
  transition: all 0.2s ease-in-out;
}

.price_:hover {
  background-color: rgba(248, 121, 117, 0.439);
}

.price_.active {
  background-color: #ec2027;
  color: #fff;
}

.price_details .yearly {
  margin-left: 10px;
}

.pro_img_ {
  position: absolute;
  width: 200px;
  aspect-ratio: 16/9;
  background-color: #eee;
  top: 10px;
  right: 10px;
}

.pro_img_ > img {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.avail_platforms {
  position: relative;
  display: flex;
  width: 70px;
  padding: 0 5px;
  border: 1px solid #eee;
  border-radius: 20px;
  margin-bottom: 10px;
}

.platform_ico {
  padding: 0 4px 5px 5px;
  font-size: 18px;
  color: #747474;
}

.add_prod {
  margin-top: 15px;
  width: 30%;
  font-size: 14px;
  margin: 0 auto;
}

.close_btn_ {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #7777;
  border: none;
  outline: none;
  /* background-color: transparent; */
  right: 0;
  top: 0;
  z-index: 9;
  font-size: 12px;
}

/* -stage card----- */
.stage-card {
  padding: 15px;
}

.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.start-button,
.end-button {
  padding: 5px 10px;
  background-color: rgb(12, 71, 12);
  color: #ffffff;
  border: none;
  border-radius: 4px;
  margin: 10px;
  font-size: 12px;
  cursor: pointer;
}

.stage-btn i {
  padding-right: 10px;
}

.end-button {
  background-color: #ec2027;
}

.edit-stage {
  color: red;
  border: none;
  outline: none;
  background-color: transparent;
}

.close-sub {
  border: 1px solid rgb(214, 46, 46);
  color: rgb(102, 28, 28);
  outline: none;
  padding: 5px;
  border-radius: 4px;
  margin: 5px;
}

.sub-btn {
  border: 1px solid rgb(255, 255, 255);
  color: rgb(235, 235, 235);
  outline: none;
  /* background: linear-gradient(
    93deg,
    rgb(230, 137, 100) 0%,
    rgb(243, 122, 128) 100%
  ); */
  background-color: #000;
  padding: 5px;
  border-radius: 4px;
  margin: 5px;
  padding-right: 15px;
  padding-left: 15px;
  font-weight: 600;
}

.re-as-btn {
  border: 1px solid rgb(255, 255, 255);
  color: white;
  outline: none;
  background: linear-gradient(
    93deg,
    rgb(230, 137, 100) 0%,
    rgb(243, 122, 128) 100%
  );
  border-radius: 4px;
  margin-top: 10px;
}

.complete {
  background-color: #125f07;
  padding: 7px 22px;
  color: white;
  border: none;
  outline: none;
  border-radius: 5px;
  margin: 2px;
  font-size: 13px;
  transition: all 0.2s ease-in-out;
}

.sub-btn-new {
  background-color: #b9eefb;
  color: rgb(26, 67, 97);
  border: none;
  padding: 5px;
  outline: none;
  border-radius: 5px;
  padding-right: 20px;
  padding-left: 20px;
  margin: 2px;
  font-size: 13px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
}

.decline_deal {
  background-color: #ebb1b1;
  color: rgb(204, 8, 8);
  border: none;
  padding: 5px;
  outline: none;
  border-radius: 5px;
  padding-right: 20px;
  padding-left: 20px;
  margin: 2px;
  font-size: 13px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
}

.decline_deal-add {
  background-color: #c4f1f3;
  padding: 7px 22px;
  color: rgb(18, 89, 122);
  border: none;
  outline: none;
  border-radius: 5px;
  margin: 2px;
  position: relative;
  font-size: 13px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
}

.complete:hover {
  background-color: rgb(11, 51, 11);
}

.complete:disabled {
  background-color: #798577;
}

.reschedule {
  background-color: #efefef;
  padding: 7px 22px;
  color: #252525;
  border: none;
  outline: none;
  font-size: 13px;
  border-radius: 5px;
  margin: 2px;
  transition: all 0.2s ease-in-out;
}

.reschedule:hover {
  background-color: #7777;
}

@media screen and (max-width: 1260px) {
  .td-bad {
    display: none !important;
  }

  .stg-st {
    display: none;
  }

  .chat-msg {
    padding-left: 15px;
    font-size: 12px;
  }

  .name-chat {
    padding-left: 15px !important;
  }

  .overflow {
    overflow-x: scroll;
    overflow-y: none;
  }

  .chated-data {
    padding-left: 20px !important;
  }

  .new-sel {
    width: 100% !important;
  }

  .profile-conv {
    width: 30px !important;
    height: 30px !important;
    border-radius: 50%;
  }
  .admin_card {
    min-height: 145px;
  }
}
.admin_card {
  padding: 10px;
}
.input-des {
  height: 50px;
  border: 1px solid rgb(214, 46, 46);
  border-radius: 12px;
  width: 100%;
}

.input-des:focus {
  height: 50px;
  border: 1px solid rgb(214, 46, 46) !important;
  border-radius: 12px;
  width: 100%;
}

.tabs {
  font-family: arial;
  margin: 0 auto;
  width: 70%;
  cursor: pointer;
  margin-top: 5vh;
}

.nav-tabs .nav-link {
  font-weight: 600;
  text-align: center;
  color: rgb(116, 114, 114);
}

.card-resolve {
  margin: 0px auto;
}

.no_ref {
  color: #747474;
  user-select: none;
  font-size: 12px;
}

.cancel-card {
  background-color: #f5c6c5;
}

.ref_card {
  min-height: 125px;
}

.nav-tabs .nav-link:hover {
  border: none;
}

label {
  text-align: center;
}

.request-data {
  margin: 0px auto;
  width: 50%;
}

.req-head {
  color: rgb(104, 102, 102);
}

.case-data {
  color: gray;
  font-weight: 400;
  padding-right: 100px;
}

.spacing-span {
  margin-left: 20px;
}

.attachment {
  width: 150px;
  padding-right: 10px;
  border-radius: 5px;
}

.profile-pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.chat-msg {
  font-size: 12px;
}

.message {
  border: 1px solid rgb(211, 205, 205) !important;
  border-radius: 5px;

  width: 100% !important;
}

.send-button {
  background-color: #ec252c;
  color: #fff !important;
  border: none;
  border-radius: 4px;
  width: 120px;
  padding: 5px;
}

.message-nav button {
  outline: none;
  border: none;
  font-size: 14px;
  color: #5e5c5c;
}

.message-nav button i {
  margin: 5px;
}

.message-nav .icon-btn:hover {
  outline: none;
  border: none;
  font-size: 16px;
  padding: 3px;
  color: #ffffff;
  background: linear-gradient(
    93deg,
    rgba(243, 113, 60, 1) 0%,
    rgba(229, 71, 79, 1) 100%
  );
  border-radius: 50%;
}

/* fp-badge */
.fp-badge {
  display: block;
  width: 100px;
  padding: 7px 0;
  border-radius: 18px;
  margin: 0 auto;
  text-align: center;
  font-size: 12px;
  user-select: none;
  color: #fff;
  font-weight: 600;
  text-wrap: nowrap;
}

.fp-badge.success {
  background-color: #d4edda;
  color: #155724;
}

.fp-badge.pending {
  background-color: #efefef;
  color: #85646c;
}

.fp-badge.failed {
  background-color: #f8d7da;
  color: #842029;
}

.fp-badge.info {
  background-color: #d1ecf1;
  color: #4b5477;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-top: none;
  border-left: none;
  background-color: #f7dada;
  color: #ec2128;
  border-right: none;
}

.nav-tabs .nav-item::after {
  border-top: none;
  border-left: none;
  border-right: none;
}

.nav-tabs .nav-item::before {
  border-top: none;
  border-left: none;
  border-right: none;
}

.badges-re {
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
  padding: 6px 6px;
  border-radius: 5px;
  font-size: 8px;
  color: #fff;
  text-align: center;
  min-width: 63px;
}

.react-tabs__tab--selected {
  background-color: rgb(247, 218, 218) !important;
  color: #cf302b !important;
  border-radius: 5px 5px 0 0;
  outline: none !important;
}

.react-tabs__tab {
  width: 50% !important;
  text-align: center;
  border: none;
  padding: 10px !important;
}

.sup-tab {
  width: 50% !important;
  list-style: none;
  border: none;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  padding: 10px;
}

.sup-tab .active {
  border: none !important;
  outline: none;
}

.sup-tab::after {
  border: none;
  outline: none;
}

.description_area {
  height: 100px;
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid #eee;
}

.description_area > textarea {
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  padding: 10px;
}

.non-edited-description {
  user-select: none;
  color: #555;
  margin-bottom: 10px;
}

.re_sched {
  font-size: 15px;
  margin-bottom: 5px;
}

/* report page */
.back-ar {
  margin-left: 10px;
  color: #e64b4d;
}

.head-rep {
  font-size: 17px;
  font-weight: 600;
  padding-bottom: 0 !important;
  margin-top: 10px;
  margin-bottom: 0 !important;
}

.head-para {
  font-size: 12px;
  color: rgb(173, 171, 171);
  padding-top: 0 !important;
  margin-top: 0 !important;
}

.head-dt {
  font-size: 14px;
  color: rgb(110, 110, 110);
  text-align: right;
  position: relative;
  top: 10px;
}

.head-name {
  font-weight: 600;
  font-size: 14px;
  position: relative;
  top: 10px;
}

.redo {
  font-size: 13px;
  position: relative;
  padding-top: 8px;
  border: 1px solid gray;
  background-color: transparent;
  padding-left: 10px;
  font-weight: 700;
  border-radius: 4px;
  height: 37px;
  padding-bottom: 0;
  padding-right: 10px;
  color: rgb(88, 88, 88);
}

.export {
  font-size: 13px;
  position: relative;
  padding-top: 10px;
  border: none;
  background-color: #1b77c2;
  padding-left: 10px;
  border-radius: 4px;
  height: 37px;
  padding-bottom: 0;
  padding-right: 10px;
  color: white;
}

.export i {
  font-size: 20px;
  font-weight: 700;
  position: relative;
  top: -8px;
  margin-right: 10px;
}

.redo i {
  font-size: 20px;
  font-weight: 700;
  position: relative;
  top: -8px;
  margin-right: 10px;
}

.rounded-pass {
  color: #15b86b;
  font-size: 30px;
  position: relative;
  top: 3px;
}

.rounded-add {
  color: #9ad0e7;
  font-size: 30px;
  position: relative;
  top: 3px;
}

.head-tab {
  font-weight: 600;

  color: gray;
  justify-content: center;

  padding: 10px;
}
.head {
  color: #11334e;
  font-weight: bolder;
  letter-spacing: 1px;
}
.form-disabled-main {
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  margin-bottom: 10px;
  padding: 5px;
  width: 100%;
}
.rounded-war {
  color: #f69008;
  font-size: 30px;
  position: relative;
  top: 3px;
}
.sign-img {
  width: auto;
  height: 100px;
  overflow: hidden;
}
.sign-img > img {
  /* width: 100%; */
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.signature-pad {
  position: relative;
  background-color: #eee;
  padding: 10px;
  width: 50%;
  height: 120px;
  border-radius: 5px;
  border: 1px dashed #ababab;
  cursor: pointer;
}
.signature-pad i {
  position: absolute;
  font-size: 3rem;
  color: #9e9e9e;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.or_ {
  display: block;
  position: relative;
  width: 30%;
  height: auto;
  text-align: center;
  left: 25%;
  transform: translateX(-50%);
  margin-top: 10px;
  user-select: none;
}
.or_ p {
  position: relative;
  display: block;
  width: 30px;
  background-color: #fff;
  color: #7777;
  padding: 5px;
  z-index: 1;
  margin: 0 auto;
  font-size: 13px !important;
  font-weight: bold;
}
.or_::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #7777;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.rounded-fail {
  color: #ef4337;
  font-size: 30px;
  position: relative;
  top: 3px;
}
.upload_btn {
  border: none;
  outline: none;
  background-color: #f0575c;
  width: 50%;
  padding: 7px 0;
  border-radius: 5px;
  color: #fff;
  transition: all 0.2s ease-in-out;
}
.upload_btn:hover {
  background-color: #c62429;
}
#upload_inp {
  display: none;
}

.content-button {
  justify-content: space-between;
  float: right !important;
  flex-direction: row-reverse;
}

.flexed {
  display: flex;
}

.ext-time {
  color: #15b86b;
  text-align: right;
  border-right: 1px solid rgb(194, 193, 193);
  font-weight: 700;
}

/* Track */
.data-rep::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.data-rep::-webkit-scrollbar-thumb {
  background: #e49e9e;
}

/* Handle on hover */
.data-rep::-webkit-scrollbar-thumb:hover {
  background: #ecb9b9;
}

.date__ {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 10px;
}

.profile-conv {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.cl-name {
  padding: 10px;
}

.description_area {
  height: 100px;
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid #eee;
}

.description_area > textarea {
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  padding: 10px;
}

.non-edited-description {
  user-select: none;
  color: #555;
  margin-bottom: 10px;
}

.re_sched {
  font-size: 15px;
  margin-bottom: 10px;
}

/* ///review card */
.verified_customer_section {
  display: grid;
  grid-template-columns: 1fr;
  border-radius: 10px;
  padding: 0 10px;
}

.rounded-img {
  width: 80px;
  height: 80px;
}

.customer-review {
  color: gray;
  font-size: 14px;
}

.anchor {
  margin-left: 2px;
  color: #de3f33;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 0 !important;
}

.image_review {
  padding-top: 10px;
  padding-left: 5px;
}

.detail {
  display: flex;
}

.col-one {
  flex: 0.2;
}

.col-two {
  flex: 0.2;
}

.col-three {
  flex: 0.6;
  text-align: right;
}

.site_ {
  color: #5e688a;
  font-size: 12px;
  letter-spacing: 0.5px;
}

.site_ span {
  margin-left: 7px;
  color: #de3f33;
  cursor: pointer;
  font-weight: 500;
  letter-spacing: 0 !important;
}

.image_review i {
  font-size: 16px;
  color: gold;
  margin-left: 0;
  margin-right: 2px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.customer_image {
  max-width: 80px;
  max-width: 80px;
}

.comp-name {
  font-size: 18px;
  line-height: 1.2rem;
}

.comp-des {
  font-size: 12px;
}

.date_ {
  position: absolute;
  font-size: 14px;
  font-weight: 600;
  color: #a0a8c3;
  right: 10px;
  bottom: 10px;
  user-select: none;
}

.customer_image img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  float: left;
  margin-right: 20px;
  object-fit: cover;
}

.cmnt_wrapper {
  background-color: #f7f7f7;
  border: 1px solid #eee;
  border-radius: 10px 10px 10px 10px;
  height: auto;
  margin: 5px 0;
  overflow: hidden;
  padding: 10px;
  position: relative;
}

.customer_name {
  font-family: Poppins;
  font-weight: 700;
  font-size: 16px;
  margin-top: 0;
  text-align: left;
  color: #2e2a39;
  margin-bottom: 0px;
}

.customer_mail {
  font-size: 13px;
  margin-bottom: 0px;
  margin-left: 10px;
  padding: 3px;
  color: #a0a8c3;
  user-select: none;
}

.customer_bage {
  float: left;
  padding-right: 5px;
}

.customer_status .customer_bage img {
  width: 12px;
  height: 15px;
}

.customer_status_content {
  padding-left: 5px;
  margin-bottom: 0;
  font-family: Poppins;
  font-weight: 400;
  font-size: 13px;
  margin-top: 2px;
  color: rgb(91, 191, 138);
}

.customer_comment {
  padding-left: 5px;
  padding-top: 10px;
  margin-bottom: 0;
  font-size: 15px;
  color: #2e2a39;
  margin-top: 3px;
  font-family: Poppins;
  font-weight: 300;
  padding-right: 5px;
  text-align: left;
  font-style: italic;
}

.comp_category {
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 600;
  color: #747474;
}

.comp_category > span {
  font-weight: 400;
  font-size: 13px;
}

.user_connection {
  font-size: 13px;
  margin-bottom: 10px;
  color: #747474;
  font-weight: 600;
  text-transform: capitalize;
}

.comp-name {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 5px;
}

.comp-name > i {
  font-size: 16px;
  color: #545353;
  padding: 5px;
}

.user_name_ {
  font-size: 22px;
  line-height: 1.2rem;
  margin-bottom: 0px;
  font-weight: 700;
}

.user_mail_ {
  font-size: 13px;
  margin: 0;
  padding: 5px 0;
  font-weight: 600;
  color: #888;
}

.comp-des {
  font-size: 14px;
  font-weight: 300;
}

.card-images {
  height: 180px;
  width: 95%;
  padding: 10px;
  padding-top: 20px !important;
  border-radius: 2px;
}

.review-card-img > img {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.company-info_ {
  padding: 0px;
  float: left;
  overflow: hidden;
  z-index: 9;
  bottom: 0;
  margin: 0;
}

.review-card-cont {
  position: relative;
  height: 200px;
}

.review-card-cont .container > h3 {
  font-weight: 600;
  color: #252525;
  line-height: 1.5rem;
}

.review-card-cont .container > span {
  font-weight: 500;
  color: #888;
  font-size: 13px;
}

.review-card-cont .container > p {
  font-size: 13px;
  margin-bottom: 0;
}

.bordered-input {
  border: 1px solid rgb(214, 211, 211);
  border-radius: 4px;
}

.review_card_ico {
  width: 50px;
  padding: 10px 20px;
  background-color: #eee;
  border-radius: 10px;
}

#review_info_ {
  /* border: 1px solid #eee; */
  font-size: 13px;
  color: #a0a8c3;
  cursor: pointer;
  border-radius: 15px;
  text-align: center;
  padding: 5px;
  white-space: nowrap;
}

.review_info_:hover {
  color: #f44336 !important;
}

.review-card-btn {
  position: absolute;
  width: auto;
  height: 30px;
  font-size: 13px;
  border-radius: 7px;
  outline: none;
  background-color: #f2d5d3;
  color: #f44336;
  border: none;
  right: 10px;
  top: 10px;
  transition: all 0.2s ease-in-out;
}

.review-card-btn:hover {
  background-color: #f44336;
  color: #eee;
}

.info-review-card {
  padding: 10px;
  /* background-color: #ddd; */
  border-radius: 10px;
  left: 10px;
  top: 10px;
}

.card_info_btn {
  border: none;
  outline: none;
  padding: 0 5px;
  background-color: transparent;
  border-radius: 10px;
  font-size: 13px;
  color: #888;
  transition: all 0.2s ease-in-out;
}

.card_info_btn:hover {
  color: #f44336;
}

#card-row_ {
  padding: 30px 0;
}

.date {
  float: right;
}

#table-badge {
  margin: 10px 0;
  background-color: transparent;
  padding: 0;
  text-align: left;
  font-size: 14px;
}

.status-completed {
  color: #125f07;
  font-size: 13px;
  font-weight: 600;
  margin: 0 10px;
}

.status-pending {
  color: #842029;
  font-size: 13px;
  font-weight: 600;
  margin: 0 10px;
}

.rdw-dropdown-wrapper {
  display: none;
}

.rdw-dropdown-wrapper:hover {
  display: none;
}

.button-re {
  background-color: transparent;
  height: 40px;
  border-radius: 4px;
  padding: 0;
  cursor: pointer;
  transition: all 1s ease-in-out;
}

.reply-button {
  float: left !important;
  outline: none;
  border: none;
  font-size: 14px;
  background-color: transparent;
  position: relative;
  padding: 10px 20px;
  color: #252525;
}

.bordered-div {
  position: relative;
  height: auto;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: height 0.2s ease;
  -moz-transition: height 0.2s ease;
  -o-transition: height 0.2s ease;
  transition: height 0.2s ease;
}

.bordered-div.show {
  max-height: 100%;
}

.drop-chat {
  float: right !important;
  outline: none;
  border: none;
  font-size: 30px;
  background-color: transparent;
  font-weight: 600;
  position: relative;
  top: -5px;
  right: 10px;
  color: #747474;
  transition: all 0.2s ease-in-out;
}

.drop-chat.rotate {
  transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
}

.chat-support {
  background-color: #fff;
  border-radius: 4px;
  transition: 0.2s ease-in-out;
  border-radius: 10px;
  border: 1px solid #cacaca;
}

.badge-text {
  color: #155724;
}

.edit-box {
  padding: 10px;
}

.close-data {
  cursor: pointer;
}

.notranslate .public-DraftEditor-content {
  min-height: 50px;
}

.rdw-editor-main {
  height: auto !important;
  overflow: none;
  box-sizing: border-box;
}

.close-btnn {
  background-color: transparent;
  outline: none;
  border: none;
  float: right;
}

/* //modal for image upload */
.kb-data-box {
  width: 100%;
  flex: 1;
}

.kb-modal-data-title {
  margin-bottom: 10px;
}

.kb-data-title h6 {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 600;
}

.kb-file-upload {
  margin-bottom: 20px;
}

.file-upload-box {
  border: 1px dashed #b6bed1;
  background-color: #f0f2f7;
  border-radius: 4px;
  min-height: 150px;
  position: relative;
  overflow: hidden;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8194aa;
  font-weight: 400;
  font-size: 15px;
}

.file-upload-box .file-upload-input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.file-link {
  color: #475f7b;
  text-decoration: underline;
  margin-left: 3px;
}

.file-upload-box .file-link:hover {
  text-decoration: none;
}

.file-atc-box {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.file-image {
  /* width: 130px;
    min-width: 130px;
    height: 85px;
    min-height: 85px; */
  width: 130px;
  height: 85px;
  background-size: cover;
  border-radius: 5px;
  margin-right: 15px;
  background-color: #eaecf1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #475f7b;
  padding: 3px;
}

.file-image img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 4px;
}

.file-detail {
  flex: 1;
  width: calc(100% - 210px);
}

.file-detail h6 {
  word-break: break-all;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 8px;
}

.file-detail p {
  font-size: 12px;
  color: #8194aa;
  line-height: initial;
  font-weight: 400;
  margin-bottom: 8px;
}

.file-actions {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}

.file-action-btn {
  font-size: 12px;
  color: #8194aa;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  margin-right: 15px;
  cursor: pointer;
}

.file-action-btn:hover {
  color: #3d546f;
  text-decoration: underline;
}

.file-atc-box:last-child {
  margin-bottom: 0;
}

.required_ {
  color: #e9192a;
}

.sub_ {
  font-size: 13px;
}

.chart-comp {
  width: 70%;
  /* margin: 0px auto; */
}

.box-row {
  margin-top: 10px;
}

.chart-head {
  font-size: 14px;
  color: #125f07;
  margin-bottom: 5px;
}

.resolved-btn {
  position: absolute;
  width: 100px;
  right: 10px;
  height: 30px;
  top: 17px;
  font-size: 13px;
}

.chat-log {
  font-weight: 600;
  text-align: center;
  position: relative;
  top: 6px;
}

.edit-feat {
  background: #fbad19;
  border: none;
  color: #fff;
  padding: 5px;
  font-size: 12px;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  width: 50px !important;
  height: 38px !important;
}

.feature-table {
  width: 70%;
}

.search-bar-div input {
  border: 1px solid #dfe0e2;
  padding: 5px;
  width: 80%;
  border-radius: 5px;
}

.search-bar-div i {
  color: #999999;
}

.sr-ch::placeholder {
  color: #c5c5c5;
}

.avoid-line {
  text-decoration: none;
  color: black;
}

.avoid-line:hover {
  color: black;
}

.avoid-ln {
  text-decoration: none;
  /* color: #fff; */
  color: #000;
}

.avoid-ln:hover {
  /* color: #fff; */
  color: #000;
}

.ticket_file {
  display: none;
}

.up_btn {
  margin: 0 5px;
  padding: 0 20px;
  border-radius: 4px;
}

.file_name {
  position: relative;
  width: 150px;
  padding: 5px 20px 5px 10px;
  background-color: #eee;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 16px;
  font-size: 10px;
  user-select: none;
  transition: all 0.2s ease-in-out;
  border: 1px solid #eee;
  margin: 5px;
}

.file_name:hover {
  border: 1px solid #7777;
}

.cls_btn {
  position: absolute;
  right: 0px;
  top: 0;
  background: transparent;
  border: none;
  outline: none;
  font-size: 15px;
}

.msg_area_wrapper {
  position: fixed;
  width: 40%;
  height: 200px;
  bottom: 0;
  right: 10px;
  background-color: #eee;
  z-index: 99;
}

.new-search {
  border: 1px solid #918f8f;
  left: 0 !;
  position: relative;
  right: 0;
  border-radius: 5px;
  margin: 0;
  padding: 5px;
  width: 88%;
}

.fp-reply-button {
  position: absolute;
  width: 100px;
  border-radius: 5px;
  outline: none;
  border: none;
  height: 30px;
  right: 10px;
  transition: all 0.2s ease-in-out;
}

.fp-reply-button:hover {
  background-color: #e0e0e0;
}

.admin-name {
  font-size: 13px;
  position: relative;
  top: 3px;
  color: rgb(27, 80, 27);
}

.active > .page-link,
.page-link.active {
  z-index: 3;
  color: #ffffff;
  background-color: #252525;
  border: none;
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.page-link {
  color: black;
  border: none;
}

.privilage {
  border-bottom: 1px solid rgb(222, 226, 230);
  padding-top: 11px;
  padding-bottom: 11px;
  padding-left: 40px;
}

.file-input-box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);

  width: 80%;
  background-color: #ffffff;
  padding: 20px;
}

.re-assign-mod {
  width: 1000px;
}

:where(.css-dev-only-do-not-override-1w4v3hc).ant-modal .ant-modal-footer {
  text-align: end;
  background: transparent;
  margin-top: 12px;
  display: none;
}

.input-box {
  padding: 10px;
  height: 38px;
  margin-top: 10px;
  display: grid;

  border: 1px dashed #cacaca;
  border-radius: 5px;
  margin-bottom: 5px;
  width: 95%;
  cursor: pointer;
}

.input-box h4 {
  font-size: 12px;
}

#stag-sub {
  height: 35px;
}

#exampleFormControlTextarea1 {
  height: 150px;
}

.re-assign {
  color: red;
  cursor: pointer;
}

.input-att {
  display: block;
  width: 100px;
  height: 20px;
  overflow: hidden;
}

.file-in {
  font-size: 50px;
  width: 120px;
  opacity: 0;
  filter: alpha(opacity=0);
  position: relative;
  top: -40px;
  left: -20px;
}

.attach-btn {
  border: none;
}

.file-name {
  color: #1e77a0;
  margin-top: 10px;
}

.att {
  color: gray;
  background-color: transparent !important;
  text-decoration: none;
  font-weight: 500;
  margin: 5px;
}

.exe-det {
  font-size: 13px;
  color: #50545e;
  margin-top: 10px;
  font-weight: 600;
}

.bb-btn {
  background-color: #155724;
  color: #fff;
  font-weight: 600;
  border: none;
  padding: 8px 15px;
  font-size: 12px;
  border-radius: 4px;
  height: 33px;
  position: relative;
  top: 5px;
}

.cl-co {
  color: #155724;
  font-weight: 600;
}

#btn-btn {
  background-color: #e9524a !important;
  border: 1px solid rgb(255, 255, 255);
  color: white;
  outline: none;
  padding: 5px;
  border-radius: 4px;
  margin: 5px;
}

.stage-com {
  position: relative;
  top: 10px;
  font-weight: 600;
  color: #155724;
}

.group-box {
  width: 70%;
  margin: 0px auto;
}

.icon-act {
  border: none;
  background: transparent;
  color: #e74c4d;
  font-size: 18px;
}

/* --------------------------------APK Management-------------------------------------------------------- */
.APK-card {
  overflow: hidden;
  width: 100%;
  background-color: #fff;
}

.file-card {
  margin: 0px auto;
  width: 90%;
}

.file-heading {
  padding-left: 10px;
  margin-top: 22px;
}

.version-table-data {
  color: gray;
  padding-left: 0px;
  padding-right: 48px;
  padding-top: 15px;
}

.APK-data {
  padding-left: 60px;
  padding-top: 10px;
  float: right;
}

.APK_btn {
  padding: 5px;
  border-radius: 4px;
  margin: 15px;
  outline: none;
  font-size: 12px;
  border: none;
  background-color: rgb(216, 235, 248);
  margin-left: 10px;
  padding-left: 27px;
  color: #499fb9;
  font-weight: 600;
  padding-right: 27px;
  transition: all 0.5s ease-in-out;
}

.APK_btn:hover {
  background-color: rgb(167, 160, 160);
}

.badge-div {
  background-color: #e1f5df;
  color: rgb(86, 116, 83);
  letter-spacing: 2px;
  padding-left: 8px;
  padding-right: 8px;
  width: 80px;
  font-size: 10px;
  padding: 2px;
  border-radius: 6px;
  text-align: center;
  font-weight: 600;
  margin-top: 10px;
  text-transform: uppercase;
  margin-left: -6px;
}

.badge-div-running {
  background-color: rgba(241, 199, 199, 0.384);
  color: red;
  letter-spacing: 2px;
  padding-left: 8px;
  padding-right: 8px;
  width: 80px;
  font-size: 10px;
  padding: 2px;
  border-radius: 6px;
  text-align: center;
  font-weight: 600;
  margin-top: 10px;
  text-transform: uppercase;
  margin-left: -6px;
}

.badge-div-expired {
  background-color: rgb(247, 241, 241);
  color: gray;
  letter-spacing: 2px;
  padding-left: 8px;
  padding-right: 8px;
  width: 80px;
  font-size: 10px;
  padding: 2px;
  border-radius: 6px;
  text-align: center;
  font-weight: 600;
  margin-top: 10px;
  text-transform: uppercase;
  margin-left: -6px;
}

.APK-management-heading {
  margin-left: -12px;
}

.APK-line {
  margin-left: 10px;
  margin-right: 15px;
}

.create-btn {
  background: linear-gradient(
    93deg,
    rgba(243, 113, 60, 1) 0%,
    rgba(229, 71, 79, 1) 100%
  );
  border: none;
  padding: 5px;
  border-radius: 5px;
  float: right;
  bottom: 10px;
  position: relative;
  right: 20px;
  color: white;
  margin-top: -33px;
}

/*--------------------------------APK Release-------------------------------------------------*/
.apk-release-heading {
  margin-left: -9px;
}

.Apk-icons {
  border: none;
  background-color: transparent;
  color: #e74c4d;
  font-size: 18px;
  padding: 5px;
  margin-right: 7px;
}

.Apk-btn {
  background: linear-gradient(
    93deg,
    rgba(243, 113, 60, 1) 0%,
    rgba(229, 71, 79, 1) 100%
  );
  border: none;
  padding: 5px;
  border-radius: 5px;
  float: right;
  bottom: 10px;
  position: relative;
  right: 20px;
  color: white;
}

.search-bar-apk input {
  border: 1px solid #dfe0e2;
  border-radius: 5px;
  padding: 5px;
  width: 100%;
}

search-bar-apk i {
  color: #999999;
}

.search-apk-btn {
  padding-top: -5px;
  margin-left: -35px;
  border: none;
  background-color: transparent;
  font-size: 17px;
  color: #6c6c6c;
}

/* ----------------------------------Create APK--------------------------------------------------------------- */
.apk-heading {
  width: 70%;
  margin: 0px auto;
}

.apk-check {
  padding: 20px;
}

.release-input {
  width: 80%;
  padding: 5px 10px;
  outline: none;
  border: none;
  background-color: transparent;
  box-shadow: 0 0 0 transparent !important;
  transition: all 0.2s ease-in-out;
}

.apk-label {
  color: #909090;
}

.upload-input {
  position: relative;
  border: 1px solid #cbcbcb;
  border-radius: 5px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
}

.radio-group {
  white-space: nowrap;
}

.radio-group input[type="radio"] {
  display: inline-block;
  margin-right: 5px;
}

.radio-group label {
  display: inline-block;
}

.right-side {
  float: right;
  color: red;
  cursor: pointer;
}

.cancel-en {
  border: none;
  padding: 5px;
  font-size: 12px;
  background-color: rgb(250, 195, 195);
  color: red;
  border-radius: 4px;
}

.create-grp {
  background: linear-gradient(
    93deg,
    rgba(243, 113, 60, 1) 0%,
    rgba(229, 71, 79, 1) 100%
  );
  border: none;
  border-radius: 4px;
  color: #fff;
  margin-top: 10px;
}

.fp-add-grp {
  background: linear-gradient(
    93deg,
    rgba(243, 113, 60, 1) 0%,
    rgba(229, 71, 79, 1) 100%
  );
  border: none;
  padding: 5px;
  border-radius: 5px;
  width: 100px;
  bottom: 10px;
  position: relative;
  left: 63%;
  color: white;
}

.assign_salesmen {
  width: 96%;
}

.profile_upload {
  margin-top: 15px !important;
}

.remove-pic {
  font-size: 13px;
  margin-left: 10px;
  color: red;
  border: none;
  background-color: transparent;
}

.named {
  font-size: 10px;
  width: 100%;
  height: 100%;
}

.file-name {
  font-size: 10px;
  width: 120px;
  height: 100%;
}

.file-name img {
  width: 100%;
  height: 100%;
}

.n-image {
  width: 50px;
  height: 50px;
}

.button-change {
  border: 1px solid #1b77c2;
  border-radius: 4px;
  padding: 3px;
  cursor: pointer;
  width: 80px;
  font-size: 12px;
  text-align: center;
  color: #1b77c2;
  font-weight: 500;
}

.chat_heads {
  border-right: 1px solid rgb(212, 212, 212);
  overflow-y: scroll;
  height: 100vh;
}

.PROFILE {
  width: 70px;
  height: 70px;
  margin: 5px;
  padding: 10px;
}

.img-pr {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.message-para {
  font-size: 14px;
  margin-top: 2px;
  white-space: nowrap;

  width: 90%;
  /* overflow: hidden;
  text-overflow: ellipsis; */
}

.point {
  cursor: pointer;
  min-height: 100px;
}

.time-chat {
  position: relative;
  top: 10px;
  right: 10px !important;
}

.chatAreaScroll {
  overflow-y: scroll;
  /* max-height: 69vh; */
  overflow-x: hidden;
  aspect-ratio: 16/9;
}

.chat-heads {
  min-height: 80px;
  max-height: 80px;
  cursor: pointer;
  overflow: hidden;
}

.msg-card-client {
  width: 48%;
  padding: 10px;
  margin: 5px;
  border-radius: 0 10px 10px 10px;
  background-color: rgb(245, 241, 241) !important;
}

.msg-card-client p {
  font-size: 14px;
  text-align: justify;
  color: #842029;
  margin: 0 !important;
}

.msg-card-admin {
  width: 48%;
  padding: 10px;
  margin: 5px;
  border-radius: 10px 10px 0px 10px;
  background-color: #fff;
  position: relative;
  left: 50%;
}

.msg-card-admin p {
  font-size: 14px;
  text-align: justify;
  color: #842029;
  margin: 0 !important;
}

.send-msg {
  background-color: #f7dada;
  width: 100%;
  height: 55px;
  z-index: 999;
  border-radius: 12px;
  margin-right: 10px;
  position: relative;

  right: 5px;
}

.send-box {
  padding: 15px;
  border-top: 1px solid #ccc;
}

.send-box form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.chatAreaScroll::-webkit-scrollbar {
  display: none;
}

.send-box .form-control {
  display: block;
  width: 85%;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #222;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.send-box button {
  border: none;
  background: #3867d6;
  padding: 0.375rem 5px;
  color: #fff;
  border-radius: 0.25rem;
  font-size: 14px;
  font-weight: 400;
  width: 24%;
  margin-left: 1%;
}

.send-box button i {
  margin-right: 5px;
}

.accept_btn {
  color: #125f07;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  margin-right: 3px;
  font-size: 20px;
  position: relative;
  padding: 5px;
}

.decline_btn {
  background-color: transparent;
  color: #94201c;
  position: relative;
  border: none;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  padding: 5px;
  font-size: 25px;
  margin-right: 3px;
}

.top {
  margin: 0 !important;
  padding: 0 !important;
}

.time {
  text-align: right !important;
  position: relative;
  padding: 10px;
  right: 10px;
  font-size: 14px;
}

.form-decline {
  width: 100%;
  border-radius: 4px;
  padding: 3px;
  border: 1px solid #cec7c7;
  margin-top: 5px;
}

.decline_btn_sub {
  border: none;
  background-color: #ceebca;
  color: #125f07;
  margin-top: 10px;
  font-weight: bold;
  width: 100px;
  padding: 8px 0px;
  border-radius: 4px;
}

.img-dic {
  width: 100px;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
}

.img-dic img {
  width: 100%;
  height: 100%;
}

.new-com {
  background-color: #c8f1c3;
  color: #fff;
  width: 150px !important;
  position: relative;
  border-radius: 4px;
  padding-bottom: 10px;
}

.comp-badge {
  color: #4b9e5e;
  padding: 10px 17px 0px;
  font-weight: 500;

  border-radius: 4px;
}

.deal_btn {
  float: right;
  background-color: #93bddf;
  color: #1b77c2;
  border: none;
  position: relative;
  left: 53% !important;
  right: 50%;
  border-radius: 4px;
  padding: 0px 10px 0;
  margin: 10px;
}

.deal_Data {
  border: 1px solid rgb(149, 161, 149);
  border-radius: 4px;
  margin: 5px;
  padding: 10px;
}

.deal-head {
  color: #1b77c2;
  font-weight: 500;
}

.date-de {
  font-size: 14px !important;
  color: #222 !important;
}

.icons-cl {
  float: right;
}

.deal_status {
  color: #155724;
  background-color: #dff8dc;
}

.gen_in {
  color: #252525;
  font-size: 20px;
  letter-spacing: 1px;
}

.in_data {
  font-size: 15px;
  font-weight: 500;
  width: 100%;
}

.inv_name {
  background-color: transparent;
  padding-left: 2px;
  border-radius: 4px;
  border: 1px solid rgb(172, 172, 219);
  padding-top: 5px;
  padding-bottom: 5px;
  width: 50%;
}

.in_inv {
  margin-right: 5px !important;
  margin-top: 5px;
}

.in_add {
  min-height: 70px;
}

.inv_dat {
  width: 100%;
  padding: 5px;
  color: #1b77c2;
  border: 1px solid #1b77c2;
  border-radius: 4px;
}

.number_inv {
  width: 20%;
  font-size: 16px;
  font-weight: 500;
  background-color: #fff;
  border: 1px solid #1b77c2;
  margin-top: 5px;
  border-radius: 4px;
  padding: 3px;
}

.inv_table {
  border: 1px solid rgb(226, 224, 224);
  margin: 10px;
}

.inv_table td,
.inv_table th {
  border-radius: 6px !important;
  border: 1px solid rgb(207, 207, 207);
}

.inv_table th {
  padding: 5px;
  height: 50px;
  border-radius: 4px;
}

.inv_table td {
  padding: 5px;
}

.invoice_table {
  border: 1px solid rgb(226, 224, 224);
  /* margin: 10px; */
  /* width: 65%; */
}

.invoice_table td,
.invoice_table th {
  border-radius: 6px !important;
  border: 1px solid rgb(207, 207, 207);
}

.invoice_table th {
  padding: 5px;
  height: 50px;
  border-radius: 4px;
}

.invoice_table td {
  padding: 8px;
}

.add_icon {
  color: #1b77c2 !important;
}

.new {
  width: 45px;
  text-align: center;
}

.sl_no {
  width: 60px;
}

.sel_inv {
  /* width: 70%; */
  border: 1px solid #e1e1e1;
  background-color: #fff;
}

tfoot {
  text-align: right;
}

tfoot tr:last-child {
  background: #f0f0f2;

  color: #395870;
  font-weight: bold;
}

tfoot tr:last-child td:first-child {
  border-bottom-left-radius: 5px;
}

tfoot tr:last-child td:last-child {
  border-bottom-right-radius: 5px;
}

.deal_num {
  color: #1b77c2;
}

.deal-list {
  float: right;
  padding: 5px;

  border: none;
  border-radius: 4px;
  background-color: #cfe8fc;
  color: #21669f;
  text-align: right;
  padding-left: 10px;
  padding-right: 10px;
}

.dealss {
  background-color: #d4e2ed;
  color: #11334e;
}

.st-bad {
  background-color: rgb(246, 180, 180);
  font-size: 11px;
  color: #a2362a;
  padding: 2px;
  border-radius: 4px;
}

.cmp_badhe {
  background-color: #ccf0e0;
  font-weight: 500;
  padding: 5px;
  border-radius: 4px;
  font-size: 11px;
  color: #155724 !important;
  cursor: crosshair;
  font-weight: 500;
}

.prop_but {
  padding: 5px;
  cursor: pointer;
  background-color: #b9d3e7;
  color: #11334e;
  border: none;
  border-radius: 4px;
  font-weight: 500;
}

.image-map {
  width: 100%;
  margin-bottom: 10px;
}

.image-map img {
  width: 30.33%;
  cursor: pointer;
  padding: 10px;
  border-radius: 4px;
}

.image-map img:hover {
  border: 1px solid rgb(223, 161, 161);
}

.selc {
  color: #11334e;
  font-weight: 600;
}

.logo-temp {
  width: 40%;
}

.pro-logo-com {
  width: 70%;
  margin-bottom: 10px;
  position: relative;
}

.text_address {
  margin: 0px auto;
  text-align: center;
}

.prop-for {
  padding: 20px;
  margin-bottom: 10px;
  padding: 5px;
  /* margin-top: 50px; */
  border-radius: 2px;
  background-color: #fff;
  /* border: 1px solid rgb(209, 206, 206); */
  border: none;
}

.temp {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.client_Name {
  border: none;
  cursor: pointer;
  background-color: transparent;
  padding: 5px;
  margin: 0;
}

.slick-next:before,
.slick-prev:before {
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #915e5e;
}

.image-slide {
  width: auto;
  height: 200px;
  /* background-color: #eee; */
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
}

.image-slide:hover {
  background-color: #eee;
}

.btn-SEND {
  color: #156a81f5;
  background-color: #c2e2ebf5;
  height: 40px;
  border: none;
  border-radius: 4px;
  font-size: 22px;
  padding: 3px;
  padding-left: 15px;
  width: 100px;
  margin-right: 10px;
  font-weight: 500;
  margin-top: 20px;
  font-size: 16px;
  padding-right: 15px;
}
.send-btn {
  color: #156a81f5;
  background-color: #c2e2ebf5;
  height: 40px;
  border: none;
  border-radius: 4px;
  font-size: 22px;
  padding: 3px;
  padding-left: 15px;
  width: 100px;
  margin-right: 10px;
  font-weight: 500;
  margin-top: 20px;
  font-size: 16px;
  padding-right: 15px;
  float: right;
}
.back_btn {
  background-color: #e1e1e1;
  /* color: #fff; */
  height: 40px;
  border: none;
  border-radius: 4px;
  font-size: 22px;
  padding: 3px;
  padding-left: 15px;
  width: 100px;
  margin-right: 10px;
  font-weight: 500;
  margin-top: 20px;
  font-size: 16px;
  padding-right: 15px;
}
.btn-download {
  color: #9c1717f5;
  background-color: #ffdfdff5;
  height: 40px;
  border: none;
  line-height: 0;
  border-radius: 4px;
  font-size: 22px;
  padding: 3px;
  padding-left: 15px;
  width: 100px;
  margin-right: 10px;
  font-weight: 500;
  margin-top: 20px;
  font-size: 20px;
  padding-right: 15px;
}

.image-slide img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.image-slide-new img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-slide.selected {
  border: 2px solid #bc201a;
}

.btn-primary-used {
  background-color: #000;
  color: #fff;
}
.style-icon {
  font-size: 12px;
  width: 12px;
  height: 12px;
}
.product_add {
  background-color: #c9e9f8;
  border: none;
  margin-left: 5px;
  padding: 5px 20px;
  text-align: center;
  border-radius: 4px;
  color: #11334e;
}

.add_button {
  background-color: #ece1e1;
  color: rgb(128, 76, 76);
  border: none;
  width: 100px;
  text-align: center;
  padding-right: 10px !important;
  padding-left: 10px !important;
  border-radius: 4px;
  position: relative;
  top: 55%;
  padding: 5px;
}

.can_pro {
  border: none;
  font-weight: 500;
  padding: 2px;
  background-color: #ffd8d8;
  color: #831414;
  border-radius: 4px;
  width: 100px;
  position: relative;
  padding: 5px;
  top: 45%;
}

.pre_img {
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
  height: 90%;
  border-radius: 6px;
}

.pre_img img {
  width: 100%;
  border-radius: 4px;
  height: 100%;
}

.prop_buttons {
  position: relative;
  top: 20%;
}

.prop_Edit {
  background-color: #d8f5c5;
  color: #2e5724;
  border-radius: 4px;
  width: 50%;
  text-align: center;
  border: none;
  font-weight: 500;
  font-size: 15px !important;
  height: 40px;
  margin-bottom: 4px;
  padding: 4px;
}

.prop_ex {
  background-color: #fcd6e3;
  color: #7c398d;
  border-radius: 4px;
  width: 50%;
  font-weight: 500;
  font-size: 15px !important;
  height: 40px;
  text-align: center;
  border: none;
  margin-bottom: 4px;
  padding: 4px;
}

.prop_send {
  background-color: #fcdbdb;
  color: #bb2a2a;
  border-radius: 4px;
  width: 50%;
  text-align: center;
  font-size: 15px !important;
  height: 40px;
  border: none;
  font-weight: 500;
  margin-bottom: 4px;
  padding: 4px;
}

.prop_prev {
  background-color: #d1cece;
  color: #727272;
  border-radius: 4px;
  width: 50%;
  font-weight: 500;
  height: 40px;
  text-align: center;
  font-size: 15px !important;
  border: none;
  margin-bottom: 4px;
  padding: 4px;
}

.new_card {
  /* min-height: 410px; */
  border: 0;
  outline: none;
}

.add_pro_data {
  border-radius: 4px;
  padding: 10px 5px;
  margin: 10px;
  border: 1px solid #eeee;
}

.des_text {
  width: 100%;
  height: 80px;
  border-radius: 4px;
  border: 1px solid #ced4da;
}

.prop_add {
  color: rgb(100, 100, 100);
  font-size: 20px;
  font-weight: 600;
}

.prop-data {
  color: rgb(87, 87, 87);
  font-size: 13px;
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: auto;
    height: auto;
  }
}

.blk {
  margin-top: 20px;
  background-color: #515151;
  color: #fff;
  font-weight: 500 !important;
  padding: 10px 5px 1px;
  font-size: 16px;
}

.client_p {
  margin-top: 20px;
  background-color: #e6e6e6;
  color: #303030;
  font-weight: 500 !important;
  padding: 10px 5px 1px;
  font-size: 16px;
}

.bold-head {
  font-weight: bolder;
  color: #000;
}

.email {
  font-weight: 600;
  font-size: 14px;
}

.head-clr {
  background-color: #515151;
  color: #fff;
  font-weight: 400 !important;
}

.grn {
  color: #000;
  font-size: 14px;
  font-weight: 500;
}

.desc_tab {
  font-size: 13px;
  font-weight: 400;
}

.download {
  border: none;
  background-color: #e7a49f;
  color: #b61818;
  padding: 5px;
  border-radius: 4px;
  font-weight: 600;
  margin-right: 10px;
  padding-right: 20px;
  padding-left: 20px;
  width: 200px;
  margin-bottom: 20px;
}

page {
  background: white;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
}

page[size="A4"] {
  width: 21cm;
  height: 29.7cm;
}

page[size="A4"][layout="landscape"] {
  width: 29.7cm;
  height: 21cm;
}

@media print {
  body {
    font-size: 18px; /* or adjust to 18px, depending on need */
  }
  page {
    background: white;
    margin: 0;
    box-shadow: 0;
  }
}

label > span {
  color: #ff0000;
}

.sub_title {
  padding: 0 10px;
  font-weight: 600;
  font-size: 14px !important;
  color: #747474;
}

/* Quantity Buttons */
.qty_wrapper {
  display: flex;
  /* width: 120px;  */
  height: 40px;
}

.qty_btn {
  border: 1px solid #ddd;
  margin: 0px;
  width: 40px;
  height: 100%;
  padding: 0;
  background: #eee;
  outline: none;
  cursor: pointer;
}

.qty_btn:hover {
  background: rgb(230, 230, 230);
}

.qty_btn:active {
  background: rgb(210, 210, 210);
}

.qty_btn.decrease-button {
  margin-right: -4px;
  border-radius: 8px 0 0 8px;
}

.qty_btn.increase-button {
  margin-left: -4px;
  border-radius: 0 8px 8px 0;
}

.qty_count {
  display: inline-block;
  text-align: center;
  border: none;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin: 0px;
  width: 40px;
  height: 100%;
  line-height: 40px;
  font-size: 11pt;
  box-sizing: border-box;
  background: white;
}

.qty_count::selection {
  background: none;
}

.line_dotted {
  border-top: 1px dotted;
}

.prod_del_btn {
  margin-top: 15px;
  background-color: #f8c9c9;
  border: none;
  margin-left: 5px;
  padding: 5px 20px;
  text-align: center;
  border-radius: 4px;
  color: #11334e;
}
.pay_btn {
  margin-top: 50px;
  margin-bottom: 10px;
  border: none;
  border-radius: 4px;
  background-color: #1e2b33;
  padding: 5px;
  width: 100px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: #fff;
}

.continue_btn {
  border: none;
  outline: none;
  width: 20%;
  padding: 10px 0;
  /* margin: 0 auto; */
  background-color: #2563eb;
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
}

.continue_btn:hover {
  background-color: rgb(24 76 189);
}

.top_bar_proposal {
  background-color: #fff;
  position: fixed;
  text-align: left;
  float: right;
  margin-bottom: 100px;
  z-index: 999;
  width: 100% !important;
}
.text-end {
  text-align: right;
}
.eee {
  background-color: #eee;
  padding-top: 150px;
}

.temp-input {
  border: none !important;
  font-weight: 500;
  margin: 0 !important;
  width: 100%;
  padding: 2px;
  height: auto;
  font-size: 16px;
  color: black !important;
}

.temp-input-email {
  border: none;
  margin: 0;
  width: 100%;
  padding: 2px;
  font-size: 12px;
  color: #000 !important;
}

.pencil {
  color: #fff;
  width: 100px;
  background-color: transparent;
}

.pencil:hover {
  color: #86c27e;
}

.address {
  font-size: 15px;
  font-weight: 400;
}

.date-pick {
  border: none;
  /* border: 1px solid rgb(197, 192, 192); */
  width: 50%;
  font-size: 10px !important;
  padding: 3px;
  height: 100%;
  border-radius: 4px;
  overflow: visible !important;
}

.bus_pro {
  font-weight: 700;
  font-size: 17px;
  color: #000;
  font-size: 25px;
  letter-spacing: 1px;
}

.date-pro {
  border: 1px solid #e0ebf2;
  padding: 4px;
  margin-right: 5px;
  border-radius: 4px;
}

.new-container {
  width: 794px !important;
  /* Approximately 210mm at 96 DPI */
  height: auto;
  /* Approximately 297mm at 96 DPI */
  padding: 0px;
  /* Padding in pixels */
  margin: 0 auto;
  background: #ffffff;
  /* Background color */
  color: #000000;
  /* Text color */
  font-size: 16px;
  /* Default font size */
  position: relative;
}

.logo-right {
  float: right;
  text-align: right;
}

.logo-right img {
  width: 60%;
}

.product_head {
  font-weight: 600;
  color: #a54f17;
  font-size: 16px;
}

.p_name {
  color: #ec2027;
  margin-bottom: 5px;
}

.p_price {
  margin: 0;
  padding: 0;
  font-weight: 800;
  font-family: "Times New Roman", Times, serif;
}

.p_qty {
  margin-top: 5px;
  font-size: 14px;
}

.p_desc {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 15px;
}

.desc {
  font-family: sans-serif;
  margin-top: 10px;
  font-size: 14px;
}

.total_p {
  font-size: 14px;
  margin-top: 2px;
}

.pro_close {
  background-color: transparent;
  color: #000;
  text-align: center;
  float: right;
  position: relative;
  margin-bottom: 10px;
  font-weight: bolder;
  border: none;
  font-weight: bolder;
  margin: 0px auto;
  border-radius: 50%;
  text-align: center;
}

.flo_rig {
  float: right !important;
  position: relative;
  top: 10px;
  left: 57%;
  height: 35px;
  align-items: right;
}

.top_sp {
  position: relative;
  top: 10px;
}

.top_sp button {
  border: none;
  border-radius: 4px;
  margin: 2px;
  padding: 3px;
}

.complete_stage {
  border: none;
  text-align: center;
  margin: 0px auto;
  padding: 5px;
  background-color: #ffe5e3;
  color: red;
  font-size: 13px;
  border-radius: 4px;
}

.button_menu {
  text-align: left !important;
  border: none;
  background-color: transparent;
  /* text-align: center; */
}

.add-pro {
  background-color: #c5f3f5;
  color: #155759;
  border-radius: 4px;
  width: 100%;
  padding-left: 10px !important;
  padding-right: 10px !important;
  float: right;
}

.can-prod {
  color: #fff;
  border-radius: 4px;
  padding-left: 10px !important;
  padding-right: 10px !important;
  border: none;
  background-color: #85646c;
  float: right;
}

.can-pro {
  background-color: #fabfbe;
  color: #b30e0b;
  border-radius: 4px;
  border: none;
  padding: 3px;
  padding-left: 10px !important;
  padding-right: 10px !important;
  float: right;
}

.red {
  color: #11334e;
}
.close_btn_message {
  float: right;
  border: none;
  color: red;
  position: relative;
  top: -10px;
  font-size: 24px;
  background-color: transparent;
}
.close_btn_message-dashboard {
  float: right;
  border: none;
  color: red;
  position: relative;

  font-size: 24px;
  background-color: transparent;
}
.loader-prop {
  color: #11334e;
  font-size: 16px;
}

.ARROW_BTN {
  margin-left: 10px;
  font-size: 18px;
  color: #11334e;
  font-weight: bolder;
}

.ARROW_BTN:hover {
  width: 40px;
  height: 40px;
  background-color: #85646c;
  border-radius: 50%;
  color: white;
  text-align: center;
}

.bac_cont {
  width: 40px;
  margin-left: 10px !important;
  height: 40px;
  cursor: pointer;
  position: relative;
  top: -50px;
}

.image_input {
  width: 59%;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 12px !important;
  padding: 0 !important;
}

.attach-pro {
  border: none;
  background-color: #f5e8cb;
  font-size: 20px;
  width: 50px;
  height: 35px !important;
  color: #000;
  border-radius: 4px;
  padding: 0;
  position: relative;
  top: 10px;
}

.send_but_prop {
  position: relative;
  top: 5px;
  border: none;
  background-color: #dbfeff;
  color: #21669f;
  border-radius: 4px;
  padding: 5px;
  font-weight: 500;
  width: 150px;
}

.pro_name {
  font-weight: 500;
  color: #11334e;
  text-transform: uppercase;
}

.pro-edit {
  border: none;
  /* margin-left: 5px; */
}

.taxAmnt {
  font-size: 30px;
  padding-left: 10px;
}

.text-c-area {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #cecece;
  padding: 2px;
}

.new-modal-edit {
  font-size: 14px !important;
  font-weight: 500;
}

.sub {
  margin-left: 7px;
}

.float-r {
  color: #11334e;
  border-radius: 50%;
  font-weight: bolder;
  margin-bottom: 10px;
  position: relative;
  left: 95%;
}

.back_nav_ {
  display: flex;
  height: 50px;
  width: 50px;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 20px;
  color: #11334e;
  cursor: pointer;
  /* background-color: #eee; */
  transition: all 0.2s ease-in-out;
  margin-top: 15px;
}

.back_nav_:hover {
  color: #fff;
  border-radius: 50%;
  background-color: #8194aa;
}

.name-pr {
  font-size: 14px;
}

.select_pro {
  border: 1px solid #e1e1e1;
}

#comp {
  color: #000 !important;
  font-size: 14px;
  font-weight: 600;
}

#comp::placeholder {
  color: #000 !important;
}

#addr {
  color: #000;
  width: 100%;
  font-size: 12px;
  height: auto;
  box-sizing: border-box;
}

.penciledit {
  color: #fff !important;
  float: right;
}

.trasdh {
  float: right !important;
  text-align: right;
  font-size: 18px;
  top: 30px;
  position: relative;

  color: rgb(102, 28, 28);
  cursor: pointer;
}

.cont-cre {
  border: none;
  background-color: #c5f3f5;
  padding: 3px;
  color: #155759;
  padding-right: 10px;
  border-radius: 4px;
  padding-left: 10px;
}

.cont_head {
  color: #000;
  font-weight: bolder;
  letter-spacing: 1px;
}

.contract {
  margin-top: 50px;
  margin-bottom: 30px;
}
.contract-main {
  margin-bottom: 20px;
  margin-top: 107px;
}
.logo-contract {
  left: 7%;
  position: relative;
  width: 70%;
}

.cont-ma {
  border: 1px solid #e1e1e1;
  border-radius: 4px;
}

.contract_details {
  width: 80%;
  margin: 0px auto;
  text-align: justify;
  /* font-weight: 500; */
  font-size: 17px;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 100px;
}
.contract_details-main {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  margin: 50px auto 0;
  text-align: justify;
  width: 80%;
}
.contract_Card-main {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  margin: 0 auto;
  padding: 25px;
  width: 75%;
}

.footer_sign {
  width: 74%;
  margin: 0px auto;
  text-align: justify;
  font-weight: 500;
  font-size: 18px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  margin-top: 100px;
}
.footer_sign-contract {
  width: 74%;
  margin: 0px auto;
  text-align: justify;
  font-weight: 500;
  font-size: 18px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  /* margin-top: 100px; */
}

.list-cont li {
  margin-top: 10px;
  margin-bottom: 10px;

  font-weight: 500;
  word-spacing: 1px;

  font-size: 15px;
}

.contract_Card {
  margin: 0px auto;
  border-radius: 4px;
  border: 1px solid gray;
  padding: 25px;
  width: 75%;
  background-color: #fff;
}

.deal_head {
  font-weight: 500;
  color: #11334e;
  font-size: 20px;
  position: relative;
  top: 20px;
  margin-bottom: 20px;
}

.deal-des {
  margin-top: 10px;
  font-size: 14px;
  border: 1px solid #e1e1e1;
  padding: 5px;
  font-family: Arial, Helvetica, sans-serif;
}

.deal_form .form-control::placeholder {
  color: black !important;
}

.deal_form label {
  font-size: 14px;
  font-weight: 500;
}

.form-disabled {
  padding: 5px;
  border-radius: 4px;
  width: 100%;
  margin-top: 5px;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: 10px;
  border: 1px solid #a0a2a1;
}

.brkdiv {
  height: 50px;
}

.footer_contract p {
  font-size: 16px !important;
  position: relative;
  top: 0px;
}
.sign-btn {
  border: none;
  outline: none;
  padding: 7px 25px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
}
.sign-btn.save {
  background-color: #fbad19;
}
.sign-btn.save:hover {
  background-color: rgb(207, 152, 51);
}
.sign-btn.clear {
  margin-left: 10px;
}
.sign-btn.clear:hover {
  background-color: #7777;
}
.info_header {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-size: 15px;
  line-height: 1em;
}
.info_header i {
  color: #e83b46;
}
.info-conts {
  font-size: 16px;
  font-weight: 400;
}
.mail_link {
  color: #000;
  text-decoration: none;
}
.contract-page-settings {
  width: 100%;
  position: relative;
  border-radius: 4px;
  background-color: #fff;
  top: 20px;
  margin: 0px auto;
  padding: 10px;
  border: 1px solid #e1e1e1;
}
.download-btn2 {
  background-color: #c62429;
  border: none;
  border-radius: 5px;
  color: #fff;
  display: block;
  margin: 0 auto;
  outline: none;
  padding: 7px 20px;
  transition: all 0.2s ease-in-out;
  width: 30%;
}
.download-btn2-1 {
  background-color: #c62429;
  border: none;
  border-radius: 5px;
  color: #fff;
  float: right;
  display: block;
  margin: 0 auto;
  outline: none;
  padding: 7px 20px;
  transition: all 0.2s ease-in-out;
  width: 150px;
  margin-bottom: 20px;
  cursor: pointer;
}
.download-btn2-1:hover {
  color: #fff;
}
.contract-page {
  position: relative;
  border-radius: 4px;
  background-color: #fff;
  top: 20px;
  margin: 0px auto;
  padding: 10px;
  border: 1px solid #e1e1e1;
}
.contract-page-main {
  position: relative;
  border-radius: 4px;
  background-color: #fff;
  top: 20px;
  width: 800px !important;
  margin: 0px auto;
  padding: 10px;

  border: none;
}

.logo-are {
  width: 70%;
  position: relative;
  left: 10%;
}

.logo-t {
  width: 30%;
  margin-bottom: 0px;
}

.data-cont {
  position: relative;
  left: 11%;
  top: 50px;
}
.data-cont-content {
  position: relative;
  left: 20%;
  top: 50px;

  font-size: 18px !important;
}

.details {
  width: 75%;
  margin: 0px auto;
}

.signature {
  width: 150px;
  margin-left: 10px;
  position: relative;
  top: 30px;
}

.images_contract {
  display: block;
  width: 200px;
  height: 250px;
  margin: 10px;
}

.images_contract img {
  width: 100%;
  height: 100%;
}

.block_slider {
  height: 100vh;
  overflow-x: scroll;
}

.block_slider::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

/* Track */
.block_slider::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.block_slider::-webkit-scrollbar-thumb {
  background: #fac3c3;
}

.close_ico {
  float: right;
}

.PRO_CONT_NAME {
  color: #11334e;
  font-weight: 500;
  font-size: 16px;
}

.btn-contract {
  color: #fff;
  border: none;
  position: relative;
  border-radius: 4px;
  padding: 5px;
  width: 200px;
  background-color: #ec2027;
  left: 5%;
  float: right;
}

.back-contract {
  background-color: #fbad19;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 5px;
  position: relative;
  left: 3%;
}

.footer-box {
  height: auto;
  padding-top: 50px;
  padding-bottom: 50px;
}

.top_cont {
  position: sticky;
  margin-left: 5%;
}

.contract-view {
  border: none;
  background-color: #499fb9;
  color: #fff;
  padding: 5px;
  font-size: 12px;
  border-radius: 4px;
}

:where(.css-i0102m).ant-modal
  .ant-modal-footer
  .ant-btn
  + .ant-btn:not(.ant-dropdown-trigger) {
  margin-bottom: 0;
  margin-inline-start: 8px;
  display: none;
}

.invoice_input {
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  width: 100%;
}

.invoice_teactarea {
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  width: 100%;
  min-height: 60px;
  background-color: #fff;
}

.inv_gen_btn {
  background-color: rgb(247, 218, 218);
  color: #cf302b;
  border: none;
  padding: 5px;
  border-radius: 4px;
  font-weight: 500;
  margin-top: 3px;
  padding-left: 10px;
  padding-right: 10px;
}

.inv_gen_ {
  border: 1px solid #11334e;
  color: #11334e;
  background-color: transparent;
  padding: 5px;
  border-radius: 4px;
  margin-top: 3px;
  padding-left: 10px;
  padding-right: 10px;
}

.gen_btn {
  background: #499fb9;
  color: #fff;
  font-weight: 500;
  padding: 5px;
  border-radius: 4px;
  border: none;
  /* width: 130px; */
  width: 70%;
  float: right;
}

.pro-gen {
  background: #000;
  color: #fff;
  font-weight: 500;
  padding: 5px;
  border-radius: 4px;
  border: none;
  width: 130px;
  text-align: center;
  position: relative;
  right: 10%;
  float: right;
}
.pro-gen-right {
  background: #000;
  color: #fff;
  font-weight: 500;
  padding: 5px;
  border-radius: 4px;
  border: none;
  width: 130px;
  text-align: center;
  float: right;
}
.pro-gen.danger {
  background-color: #e54750;
}
.Invoice_footer {
  border-radius: 4px;
  border: 1px solid #e1e1e1;
  width: 60%;
}

.draft_btn {
  position: relative;
  background: #747474;
  color: #fff;
  margin-bottom: 10px;
  font-weight: 500;
  padding: 5px;
  border-radius: 4px;
  border: none;
  width: 130px;
  text-align: center;
  position: relative;
  right: 10%;
  float: right;
  transition: all 0.2s ease-in-out;
}
.draft_btn:hover {
  background-color: #2e2a39;
}
.draft_btn > i {
  font-size: 12px;
  padding: 0 7px;
  margin: 0;
}
.draft_badge {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #e54750;
  border-radius: 50%;
  right: -5px;
  top: -5px;
}

.con-info {
  color: #1b77c2;
  font-size: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.text-left {
  text-align: left;
}

.Invoice_head {
  margin-left: 50px;
  width: calc(100% - 100px);
  margin: 0 !important;
  padding-top: 50px;
}

.invoice_Add {
  width: 50%;
  text-align: justify;
  font-family: "Bitter", serif;
  font-size: 13px;
}

.custom-row {
  margin: 20px 0;
  padding: 10px;
}

.custom-col {
  padding: 15px;
  margin-bottom: 10px;
}

.col-6 {
  width: 40%;
}

.logo-arera {
  width: 200px;
  height: auto;
}

.logo-arera img {
  width: 100%;
  height: 100%;
  margin-top: 20px;
  /* padding: 10px; */
  position: relative;

  object-fit: contain;
}

.table-Info {
  float: left !important;
  position: relative;
  top: 40px;
}

.table-Info table {
  padding: 10px;
  text-align: left;
}

.table-Info table th {
  font-weight: 400;
  margin: 10px;
  padding: 5px;
  text-align: left;
}

.table-Info table td {
  padding: 5px;
}

#tblSample {
  width: 100%;
}

#tblSample th {
  text-align: left;
  border: 1px solid black;
}

/* .inv_bg {
  margin: 0 !important;
  height: auto;
  padding: 0 !important;
} */
.inv {
  background-color: #fff;
  margin-top: 50px;
  border-radius: 4px;
}

.in_table {
  width: 100%;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
}

.in_table tr {
  padding: 10px;
}

.in_table th,
td {
  font-size: 14px;
  /* font-family: "Open Sans", sans-serif; */
  line-height: 1;
  vertical-align: top;
  padding: 4px;
}

.in_table td.subtotal {
  color: #1e2b33;
  text-align: right;
}

.one-btn_inv {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background: #fbad19;
  border: none;
  color: #fff;
  padding: 5px;
  font-size: 12px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 0px;
  transition: all 0.2s ease-in-out;
  width: 100px !important;
  height: 38px !important;
}

.exis_btn {
  background-color: transparent;
  border: none;
  color: #11334e;
  font-weight: 500;
  font-size: 20px;
}

.brk_div {
  margin-top: 10px;
}

.duw {
  color: red;
  font-size: 14px !important;
}

.animated-arrow {
  animation: arrowAnimation 1s infinite alternate;
}

@keyframes arrowAnimation {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(10px);
  }
}

.gen-right {
  float: right;
}

.more_details {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 12px !important;
  width: 85%;
}

.more_details h6 {
  font-size: 14px;
  font-weight: 700;
}

.under_line {
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 10px;
}

.small_input {
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  width: 70%;
}

.dis-in {
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  width: 30%;
  text-align: right;
}

.trash-pro {
  color: red;
  border: none !important;
  outline: none;
  background-color: transparent;
  font-weight: 500;
}

.sub_btn {
  float: right;
  border: none;
  background-color: #f0bfbf;
  color: #ec2027;
  border-radius: 4px;
  width: 100px;
  padding: 5px;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 600;
}

/* .gen-area {
  padding-top: 20px;
} */
.antd-sel {
  color: black !important;

  width: 100% !important;
}

:where(.css-dev-only-do-not-override-1w4v3hc).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  position: relative;
  background-color: #ffffff;
  border: px solid #e1e1e1 !important;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ref {
  text-decoration: none;
  text-align: center;
  font-weight: 300 !important;
}

.list-pout {
  color: #9b2d29;
  list-style: none;
  font-size: 12px;
}

.discount {
  width: 70px;
  font-size: 14px;
  box-shadow: none;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  background-color: #fff;
}

.propsal-cont {
  width: 794px !important;
  height: auto;
  padding: 0px;
  margin: 0 auto;
  color: #000000;
  font-size: 16px;
  background: transparent;
  border: none;
  position: relative;
}

.attach {
  width: 820px !important;
  /* Approximately 210mm at 96 DPI */
  padding: 0px;
  /* Padding in pixels */
  margin: 0 auto;
  color: #ff0000;
  margin-top: 80px !important;
  font-size: 18px;
  position: relative;
}

.inv_bg {
  width: 794px !important;
  /* Approximately 210mm at 96 DPI */
  height: auto;
  /* Approximately 297mm at 96 DPI */
  padding: 0px;
  /* Padding in pixels */
  margin: 0 auto;
  font-family: "Poppins", sans-serif !important;
  background: #ffffff;
  /* Background color */
  color: #000000;
  /* Text color */
  font-size: 16px;
  /* Default font size */
  position: relative;
}

.right {
  float: right !important;
}

.cancelled {
  background-color: #f7dada !important;
  color: #b9191e;
  border-radius: 4px;
  font-weight: 500;
  margin-left: 10px;

  padding: 3px;
}

.add_proposal_ {
  position: relative;
  width: 95%;
  padding: 10px;
  margin: 10px;
  display: flex;
  cursor: pointer;
  border: 1px dashed #7777;
}

.add_proposal_ > h2 {
  display: block;
  margin: 0 auto;
  user-select: none;
  color: #7777;
}

.add_proposal_ > i {
  font-size: 1.5rem;
  color: #7777;
}

.alert_due {
  border-color: #f01e17 !important;
}

.btn_grp_proposal {
  position: absolute;
  display: flex;
  right: 10px;
  top: 10px;
}

.cust_line_height {
  line-height: 2.5rem;
}

.proposal_ico_ {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.2s ease-in-out;
}

.proposal_ico_.success {
  display: flex;
  color: #125f07;
  background-color: #e8fdf4;
  padding: 7px 15px;
  font-size: 10px;
  border-radius: 5px;
  user-select: none;
  cursor: auto;
}

.proposal_ico_.success > p {
  font-size: 11px;
  font-weight: 500;
  color: #252525;
  cursor: auto;
  padding: 0 5px;
  margin-bottom: 0px;
}

.proposal_ico_.pending {
  display: flex;
  color: #f19810;
  background-color: #fdfbe9;
  padding: 7px 15px;
  font-size: 10px;
  border-radius: 5px;
}

.proposal_ico_.pending > p {
  font-size: 11px;
  font-weight: 500;
  color: #252525;
  padding: 0 5px;
  margin-bottom: 0px;
}

.proposal_ico_.pending:hover {
  background-color: #cbc8ac;
}

/* #deal_cmp {
  position: absolute;
  right: 20px;
} */
.alert-msg {
  color: #b61818;
  padding-top: 10px;
  padding-bottom: 1px;
  border-radius: 4px;
  text-align: center;
}

.small {
  font-size: 10px;
}

.download_icon {
  font-size: 15px;
  margin-top: 5px;
}

.date-proposal {
  font-size: 17px !important;
  font-weight: 500;
}

.date {
  position: relative;
  top: 15px;
}

.address_lead {
  padding-right: 20px !important;
}

.edit-lead {
  border: 1px solid #fb4336;
  border-radius: 4px;
  font-size: 13px;
  font-weight: bolder;
  color: #fb4336 !important;
}

.date-in {
  height: auto;
}

.adr-prop p {
  font-size: 10px;
  font-weight: 400;
  text-align: justify;
}

.for-address {
  width: 50%;
}

.for-details {
  font-size: 10px;

  height: auto;
}

.table-pro {
  /* border: 1px solid black !important; */
  margin-top: 50px;
  border-radius: 2px;
}

.table-pro th {
  font-size: 12px !important;
  background-color: #d3d3d3 !important;
  color: #000;
}

.bor {
  border: 1px solid black !important;
}

.ad123 {
  height: 80px !important;
}

.category-select {
  width: 100%;
  border: none !important;
  /* height: 70px !important; */
}

:where(.css-dev-only-do-not-override-1w4v3hc).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border: none;
}

.cancel-btn {
  border: none;
  color: red;
  background-color: rgb(250, 195, 195);
  font-weight: 500;
  padding: 4px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  border-radius: 4px;
}

.address-contract {
  width: 60%;
}

.company-selection {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.inv_head {
  position: relative;
  top: 50px;
}

.sl-no {
  width: 10%;
}

.narrow-col {
  width: 20px;
  /* Adjust width as needed */
  text-align: center;
}

.message-inv {
  width: 100%;
  border: 1px solid #eee;
  min-height: 50px;
}

.attach-inv {
  border: 1px solid #eee;
  width: 100%;
  background-color: #fff;
  color: #ec2027;
}

.auto-msg {
  position: absolute;
  bottom: 19%;
  font-size: 11px;
  text-align: center;
  margin: 0px auto;
  left: 42%;
}

@media screen and (max-width: 786px) {
  .flex-one {
    display: none;
  }

  .flex-two {
    display: block;
    margin: 0 auto;
  }
}

.showAlert_wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #7777;
  overflow: hidden;
  z-index: 999;
}

.showAlert_card {
  position: absolute;
  width: 50%;
  height: auto;
  padding: 1rem 2rem;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 4px;
}

.showAlert_card > .title {
  user-select: none;
  color: #e54750;
  font-size: 13px;
}

.showAlert_card .title > i {
  font-size: 10px;
}

.content__ {
  user-select: none;
  color: #747474;
}

#adrs__det {
  width: 280px !important;
  font-size: 13px;
  font-weight: 500;
  text-transform: lowercase;
  user-select: none;
  margin-top: 5px;
}

.toggle_btn {
  text-decoration: none;
  font-weight: 500 !important;
  padding: 10px 0 !important;
}

.toggle_btn > a {
  text-decoration: none;
}

.trash_btn_props {
  border: none;
  outline: none;
  background: transparent;
  color: #e9192a;
}

.trash_btn {
  border: none !important;
  outline: none !important;
  background-color: #af2328;
  padding: 5px 10px;
  border-radius: 4px;
  color: #fff;
  width: 100px;
  height: 30px;
  margin-left: 10px;
  transition: all 0.2s ease-in-out;
}

.trash_btn:hover {
  background-color: #a32020;
}

.trash_btn:disabled {
  background-color: #e49e9e !important;
  border: none;
  outline: none;
}

.view_proposal_btn {
  border: none;
  outline: none;
  background: transparent;
  transition: all 0.2s ease-in-out;
}

.view_proposal_btn.up {
  transform: rotate(-180deg);
}

.view_proposal_btn.down {
  transform: rotate(0deg);
}

.tbl_hdr_prop {
  color: #747474;
}

.view_modal_proposal_btn {
  display: block;
  border: none;
  outline: none;
  background-color: #e54750;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 4px;
  color: #fff;
}

.company_badge {
  font-size: 12px;
  background-color: #e49e9e5e;
  color: #252525;
  text-align: center;
  padding: 5px 7px;
  border-radius: 10px;
  user-select: none;
}

.proposal_btn {
  border: none;
  outline: none;
  border-radius: 4px;
  background-color: #125f07;
  color: #fff;
  padding: 2px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}

.add_contract_btn {
  display: block;
  border: none;
  outline: none;
  font-size: 12px;
  font-weight: 600;
  background-color: #15b86b;
  padding: 5px 15px;
  color: #fff;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  margin: 0 auto;
}

.add_contract_btn:hover {
  background-color: #125f07;
}

.details-row > td {
  border: none !important;
  border-bottom-width: 0px !important;
}

.details-content {
  height: auto;
  overflow: hidden;
  overflow-y: scroll;
  transition: all 0.2s ease-in-out;
}

.details-content.hide {
  height: 0px !important;
}

.download_btn_proposal {
  text-decoration: none;
  background-color: #1b77c2;
  border-radius: 4px;
  padding: 7px 10px !important;
  color: #fff !important;
  font-weight: 400 !important;
  width: 120px !important;
  height: 30px;
  text-align: center;
}

.details-content::-webkit-scrollbar {
  width: 5px;
}

.details-content::-webkit-scrollbar-track {
  background-color: #eee;
}

.details-content::-webkit-scrollbar-thumb {
  background-color: #747474;
  outline: none;
}

.table_sub_title_proposal {
  font-size: 13px;
  font-weight: 600;
  color: #747474;
  user-select: none;
}

.proposal_lead_badge {
  position: absolute;
  color: #e54750;
  padding: 5px 10px;
  border-radius: 5px;
  top: 0;
  right: 0;
}

.proposal_information {
  padding: 0 20px;
  color: #747474;
  font-size: 13px;
  text-align: right;
  user-select: none;
}

.proposal_information > i {
  color: #e54750;
}

.lead-propo span {
  padding: 2px;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px !important;
  color: #af2429;
}

.pro-edit-btn {
  border: none;
  border-radius: 16px;
  color: #155724;
  font-weight: 700;
  width: 100px;
  background-color: #d4edda;
  padding: 10px;
}

.adrs_det-1 {
  font-size: 14px !important;
}

.pro-feat-000 small {
  padding-right: 50px !important;
}

.mail_modal_wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(8px);
  /* Safari 9+ */
  backdrop-filter: blur(8px);
  /* Chrome and Opera */
  box-shadow: inset 0 0 0 200px rgba(255, 255, 255, 0.08);
  overflow-x: scroll;
  top: 0%;
  z-index: 999;
  transition: all 0.2s ease-in-out;
  font-family: "Josefin Sans", sans-serif;
}

.mail_modal {
  position: absolute;
  width: 90%;
  height: auto;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #7777;
  padding: 10px 20px;
  left: 50%;
  top: 100px;
  transform: translateX(-50%);
  overflow-y: scroll;
}

.close_btn_mail_modal {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 20px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.send_btn_new {
  border: none;
  outline: none;
  padding: 10px 0;
  width: 10%;
  margin: 0 auto;
  background-color: #f8434a;
  border-radius: 5px;
  color: #fff;
}

.send_btn_new:disabled {
  background-color: #f0bfbf;
}

.mail_title__ {
  font-size: 22px;
  text-transform: capitalize;
  margin: 20px 0;
}

.fp-label {
  font-size: 13px;
  color: #727272;
}

.fp-label > span {
  color: #f01e17;
}

/* ----------------- Web Responsive Changes ------------------------------------------------------ */
@media screen and (max-width: 786px) {
  .head-log {
    text-align: center;
  }

  .add {
    bottom: 68px;
  }

  .payment.mastercard {
    width: 400px;
  }

  .gen_btn {
    width: 27%;
    position: relative;
    bottom: 35px;
  }

  .subscription_ {
    margin-top: 10px;
  }

  .mer_inoice {
    overflow-x: scroll;
  }

  .toggle-side {
    left: 183px;
  }
}

@media screen and (max-width: 600px) {
  .head-h5 {
    font-size: 25px;
  }

  .total {
    font-size: 20px;
  }
  .dash-img-res {
    margin: 0px auto;
    width: 26%;
  }
}

@media screen and (max-width: 500px) {
  .lead_card {
    margin-top: 30px;
  }
  .add {
    bottom: 0px;
    margin-top: 20px;
    right: 0;
  }
  .nav-for-tab {
    display: block;
  }
  .side-show {
    display: block;
  }
  .button-div {
    width: 50%;
  }
  .admin_card {
    padding: 0;
  }
  .payment.mastercard {
    width: 300px;
  }

  .proposal_lead_badge {
    left: 60px;
  }

  .custo_name {
    margin-top: 22px;
  }
}
.iconsbtn {
  font-size: 18px !important;
  padding: 1px;
  width: 22px;
  color: #125f07;
  border-radius: 50%;
  /* border: 1px solid green; */
  border: none;
  font-weight: 700 !important;
}
.deleteiconsbtn {
  font-size: 18px !important;
  padding: 1px;
  width: 22px;
  float: right;
  color: red;
  border-radius: 50%;
  text-align: right;
  border: none;
  font-weight: 700 !important;
}
.item_input {
  width: 100%;
  outline: none;
  border: none;
}
.item_span_ {
  padding: 5px 0;
}
.delete_btn_item {
  border: none;
  outline: none;
  background-color: transparent;
  color: #f01e17;
}

.normal_field {
  border: 1px solid #cbcbcb;
  margin: 2px;
  border-radius: 3px;
  margin-left: 10px;
  padding: 5px;
  width: auto;
}
.edit_btn_enquiry {
  background-color: #e1e1e1;
  border: none;
  width: 50px;
  font-weight: 600;
  color: #000;
  padding: 5px;
  border-radius: 4px;
}

.warn-modal > h4 {
  font-size: 16px;
  color: #747474;
  user-select: none;
}

.warn-modal h4 > i {
  color: #e54750;
  font-size: 18px;
}
.warn-modal > p {
  font-size: 18px;
  padding: 10px 0;
  color: #252525;
}
.warn_btn {
  border: none;
  outline: none;
  margin-right: 10px;
  padding: 7px 20px;
  border-radius: 4px;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
}
.warn_back {
  background-color: #252525;
  color: #fff;
}

.warn_btn:hover {
  background-color: #757575;
}
.vat_sec {
  display: flex;
}
.add-pac {
  border: none;
  border-radius: 4px;
  width: 100px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: black;
  background-color: #e1e1e1;
  text-align: center;
  margin: 0px auto;
  font-weight: 700;
  padding: 5px;
  text-transform: uppercase;
  border: 1px solid #e1e1e1;
}
.quantity-field {
  display: flex;
  height: 40px;
  margin: 0 auto;
}

.quantity-field .value-button {
  border: 1px solid #ddd;
  margin: 0px;
  width: 40px;
  height: 100%;
  padding: 0;
  background: #eee;
  outline: none;
  cursor: pointer;
}

.quantity-field .value-button:hover {
  background: rgb(230, 230, 230);
}

.quantity-field .value-button:active {
  background: rgb(210, 210, 210);
}

.quantity-field .decrease-button {
  margin-right: -4px;
  border-radius: 8px 0 0 8px;
}

.quantity-field .increase-button {
  margin-left: -4px;
  border-radius: 0 8px 8px 0;
}

.quantity-field .number {
  display: inline-block;
  text-align: center;
  border: none;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin: 0px;
  width: 40px;
  height: 100%;
  line-height: 40px;
  font-size: 11pt;
  box-sizing: border-box;
  background: white;
  font-family: calibri;
}

.quantity-field .number::selection {
  background: none;
}

/*
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
*/
.add-course- {
  display: flex; /* Enables flexbox */
  justify-content: center; /* Centers content horizontally */
  align-items: center; /* Centers content vertically */
  float: right;
  text-align: center;
  background-color: #e8efff;
  border: none;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  color: #2b67ec;
  font-size: 1.8rem;
  cursor: pointer; /* Optional: Makes the button more interactive */
}

.add_meals_card {
  padding: 20px;
}

.menu_dlt_btn_ {
  border: none;
  outline: none;
  background-color: transparent;
  color: #e54750;
  padding: 10px 0;
}
.text_btm_ {
  position: absolute;
  bottom: 9px;
}

#fp-table {
  /* width: 50%; */
}
#fp-table > thead {
  vertical-align: middle;
}
.sub_service_total {
  position: absolute;
  right: 10px;
  bottom: 0;
  /* font-weight: 700; */
  margin-bottom: 0px;
}
.pack_name {
  font-size: 14px;
  /* margin: 0; */
  font-weight: 700;
  color: #252525;
}
.toggle {
  position: relative;
  display: inline-block;
  /* margin-top: 0.5rem; */
  width: 42px;
  height: 19px;
  background-color: hsl(0, 0%, 85%);
  border-radius: 25px;
  cursor: pointer;
  margin-left: 10px;
  transition: background-color 0.25s ease-in;
}
.toggle::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 15px;
  height: 15px;
  background-color: white;
  border-radius: 50%;
  transition: all 0.25s ease-out;
}
#cb-toggle:checked + .toggle {
  background-color: hsl(102, 58%, 39%);
}
#cb-toggle:checked + .toggle::after {
  transform: translateX(24px);
}
.hide-me {
  opacity: 0;
  height: 0;
  width: 0;
}
.save_as_draft {
  border: 1px solid #7777;
  outline: none;
  width: 20%;
  padding: 10px 0;
  /* margin: 0 auto; */
  background-color: #fff;
  color: #252525;
  border-radius: 5px;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
  margin-left: 10px;
}
.save_as_draft:hover {
  background-color: #d4e2ed;
}
.btn-grp {
  display: flex;
  align-items: center;
  justify-content: center;
}
.borderless table {
  border-top-style: none;
  border-left-style: none;
  border-right-style: none;
  border-bottom-style: none;
}
.borderless table tr {
  padding: 10px;
}
.words p {
  font-size: 16px;
  /* letter-spacing: 1px; */
}

.input-div {
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  padding: 3px;
}
.container1 {
  min-height: 100vh;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  font-family: system-ui, -apple-system, sans-serif;
}
.max-width {
  max-width: 800px;
}
.card1 {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px;
  padding: 24px;
}

.title1 {
  font-size: 20px;
  font-weight: 600;
  color: #000;
  margin: 0;
  margin-bottom: 4px;
}

.subtitle1 {
  color: #b91c1c;
  margin: 0;
  margin-bottom: 20px;
}

.content1 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.row1,
.row2,
.row3,
.row4 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e5e7eb;
}

.currency1 {
  color: #000;
  margin-right: 8px;
}

.input1,
.input2,
.input3,
.input4,
.input5,
.input6 {
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 14px;
  color: #000;
  background-color: #fff;
  width: 100%;
}

.input3,
.input5 {
  width: 60px;
  margin: 0 8px;
}

.payment-text1,
.payment-text2,
.payment-text3,
.due-text1,
.due-text2,
.due-text3 {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #000;
  font-size: 14px;
}

.amount-group1,
.amount-group2,
.amount-group3 {
  display: flex;
  align-items: center;
  gap: 8px;
}

.warning1 {
  color: #b91c1c;
  margin-top: 20px;
  font-size: 14px;
}

@media (min-width: 640px) {
  .row1 {
    flex-direction: row;
    align-items: center;
  }

  .input1 {
    width: auto;
    min-width: 200px;
  }

  .row2,
  .row3,
  .row4 {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }
}
.proposal-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.proposal-header {
  background: white;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.back-button {
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--text-color);
  transition: color 0.3s ease;
}

.back-button:hover {
  color: var(--accent-color);
}

.action-buttons {
  display: flex;
  gap: 1rem;
}

.download-btn,
.send-btn,
.draft-btn {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
}

.download-btn {
  background-color: var(--light-gray);
  color: var(--text-color);
}

.send-btn {
  background-color: var(--success-color);
  color: white;
}

.draft-btn {
  background-color: white;
  color: var(--warning-color);
  border: 1px solid var(--warning-color);
}

.attachment-section {
  background: white;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.file-input {
  flex: 1;
  padding: 0.5rem;
}

/* A4 Paper Styling */
.proposal-content {
  background: white;
  width: 210mm;
  margin: 0 auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.proposal-page {
  padding: 20mm;
  min-height: 297mm;
  position: relative;
}

.company-header {
  /* display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 2rem;
  margin-bottom: 3rem; */
}

.company-logo {
  max-width: 150px;
}
.patment {
  margin-top: 30px !important;
  margin: 10px;
}

.noborder td {
  border: none !important;
}
.noborder {
  border: none !important;
}
.company-info {
  font-size: 0.9rem;
  line-height: 1.5;
}

.company-info h2 {
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.proposal-details {
  text-align: right;
}

.proposal-details h1 {
  color: var(--primary-color);
  font-size: 2rem;
  margin-bottom: 1rem;
}

.date-section {
  font-size: 0.9rem;
}

.due-date input {
  border: none;
  border-bottom: 1px solid var(--border-color);
  padding: 0.3rem;
  margin-left: 0.5rem;
}

.client-section h3 {
  color: var(--primary-color);
  text-transform: uppercase;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.client-details {
  font-size: 0.9rem;
  line-height: 1.5;
}

.client-name {
  font-weight: bold;
  text-transform: uppercase;
}

.package-section {
  margin-bottom: 2rem;
}

.package-section h3 {
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.package-description {
  font-size: 0.9rem;
  line-height: 1.6;
  text-align: justify;
}

.proposal-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 2rem;
}

.proposal-table th,
.proposal-table td {
  border: 1px solid var(--border-color);
  padding: 0.8rem;
  font-size: 0.9rem;
}

.proposal-table th {
  background-color: var(--light-gray);
  font-weight: bold;
}

.proposal-footer {
  margin-top: 3rem;
  font-size: 0.8rem;
  color: var(--secondary-color);
}

.notes {
  margin-bottom: 1rem;
}

.note-text {
  font-style: italic;
}

.contact-info {
  text-align: center;
  margin-bottom: 1rem;
}

.generated-text {
  text-align: center;
  color: var(--text-color);
  opacity: 0.7;
}

/* Warning Modal Styles */
.warn-modal {
  padding: 2rem;
  text-align: center;
}

.warning-icon {
  color: var(--warning-color);
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.warn-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 2rem;
}

.warn-back,
.warn-cancel,
.warn-save {
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.warn-back {
  background-color: var(--danger-color);
  color: white;
}

.warn-cancel {
  background-color: var(--light-gray);
  color: var(--text-color);
}

.warn-save {
  background-color: var(--warning-color);
  color: white;
}
.proposal p {
  font-size: 16px;
  margin-bottom: 20px;
  line-height: 1.5;
}
.borderred {
  border-top: 1px solid #c9c9c9;
  border-bottom: 1px solid #c9c9c9;
}
.toggle-button {
  width: 60px;
  height: 30px;
  border-radius: 15px;
  border: none;
  background-color: #ccc;
  color: white;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.toggle-button.active {
  background-color: #28a745;
  transform: translateX(5px);
}
.terms-container h1 {
  padding-top: 50px;
  margin-bottom: 20px;
}
.add_btn-content {
  float: right;
  border: 1px solid black;
  border-radius: 4px;
  color: black;
  /* padding-left: 10px;
  padding-right: 10px; */
  width: 30px;
  font-weight: 700;
  /* position: relative;
  right: 20px;
  bottom: 10px; */
  background-color: #fff;
}
.remove-btn {
  border: none;
  background-color: transparent;
  float: right;
  color: #000;
  margin-bottom: 10px;
}
.accept-btn {
  background: #007bff;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease;
}
.red {
  color: red;
}
.read-more-btn {
  border: none;
  background-color: transparent;
}
.checkbox_container_1616 span {
  color: #000;
  text-align: left;
}
.terms_content_1414 h3 {
  font-weight: 700;
}
.success-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #f6f8fd 0%, #f1f4f9 100%);
  padding: 20px;
}

.success-card {
  background: white;
  border-radius: 20px;
  padding: 40px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
  text-align: center;
  max-width: 480px;
  width: 100%;
  position: relative;
  animation: slideUp 0.6s ease-out forwards;
}

.success-icon {
  width: 80px;
  height: 80px;
  background: #4caf50;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 24px;
  color: white;
  transform: scale(0);
}

.success-icon.animate {
  animation: scaleIn 0.5s ease-out 0.3s forwards;
}

.success-title {
  color: #2d3748;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 16px;
}

.success-message {
  color: #718096;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 32px;
}

.success-details {
  background: #f7fafc;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 32px;
}

.detail-item {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
}

.detail-item:not(:last-child) {
  border-bottom: 1px solid #e2e8f0;
}

.detail-label {
  color: #718096;
  font-size: 14px;
}

.detail-value {
  color: #2d3748;
  font-weight: 600;
  font-size: 14px;
}

.success-button {
  background: #4caf50;
  color: white;
  border: none;
  padding: 12px 32px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.2s, background 0.2s;
}

.success-button:hover {
  background: #43a047;
  transform: translateY(-2px);
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
.confirm-badge {
  color: #fff;
  border: none;
  letter-spacing: 0.5px;
  background-color: green !important;
  font-weight: 500;
}
.Toastify__progress-bar--animated {
  margin: 0px auto !important;
  text-align: center !important;
}

.proposal2025 {
  max-width: 800px;
  margin: 40px auto;
  padding: 40px;
  background: white;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.header2025 {
  text-align: center;
  margin-bottom: 40px;
}

.logo2025 {
  width: 120px;
  height: auto;
  margin: 0 auto 20px;
}

.title2025 {
  font-size: 24px;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 4px;
}

.prepared2025 {
  font-size: 14px;
  color: #666;
  margin-bottom: 2px;
}

.details2025 {
  margin: 30px 0;
  border: 1px solid #e5e7eb;
  padding: 20px;
  border-radius: 8px;
}

.details2025-title {
  font-weight: 600;
  margin-bottom: 15px;
  color: #1a1a1a;
}

.details2025-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
  font-size: 14px;
}

.details2025-label {
  font-weight: 500;
  color: #4b5563;
}

.table2025 {
  width: 100%;
  border-collapse: collapse;
  margin: 30px 0;
}

.table2025 th,
.table2025 td {
  padding: 12px;
  border: 1px solid #e5e7eb;
  text-align: left;
}

.table2025 th {
  background-color: #f9fafb;
  font-weight: 600;
  color: #374151;
}

.table2025-amount {
  text-align: right;
  font-weight: 500;
}

.total2025 {
  font-weight: 600;
  font-size: 16px;
  color: #1a1a1a;
}

.terms2025 {
  margin-top: 40px;
}

.terms2025-title {
  font-weight: 600;
  margin-bottom: 15px;
  color: #1a1a1a;
}

.terms2025-list {
  list-style-type: decimal;
  padding-left: 20px;
  font-size: 14px;
  color: #4b5563;
}

.terms2025-list li {
  margin-bottom: 8px;
}

.footer2025 {
  margin-top: 40px;
  text-align: center;
  font-size: 14px;
  color: #6b7280;
  padding-top: 20px;
  border-top: 1px solid #e5e7eb;
}
.ql-editor {
  min-height: 200px !important;
}
.sub-btn-mail {
  width: 150px;
  margin-top: 50px;
  background-color: #000;
  border-radius: 4px;
  color: #fff;
  border: none;
  padding: 10px;
}
.clr_btn {
  background-color: #000;
  color: #fff;
  border-radius: 4px;
  margin-bottom: 10px;
  margin-top: 10px;
  border: none;
  padding: 5px 20px;
}
.toggle-mode {
  background-color: #635bff;
  color: #fff;
  border-radius: 20px;
  padding: 5px 15px;
  border: none;
  float: right;
}
.card-gray {
  background-color: #eee !important;
}
.edit-stripe-values {
  color: #fff;
  background-color: #000;
  border: none;
  padding: 3px 10px;
  border-radius: 5px;
  position: relative;
  left: 25px;
}
.toggle-state_btn {
  position: absolute;
  background-color: transparent;
  right: 10px;
  color: #ffff;
  background-color: #747474;
  padding: 3px 10px;
  outline: none;
  border-radius: 3px;
  border: none;
  transition: all 0.2s ease-in-out;
}
.toggle-state_btn:hover {
  background-color: #252525;
}
.f-12 {
  font-size: 13px;
}
