.loader {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100%;
    min-height: 100%;
  }
  .loader-lottie {
    position: absolute;
    width: 150px;
    height: 150px;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
  }
  