.error_wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.session_error_wrapper{
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: transparent;
}
.error_content{
  position: fixed;
  width: 70%;
  height: auto;
  padding: 20px;
  /* background-color: #fff; */
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.error_img{
  width: auto;
  height: 350px;
  overflow: hidden;
}
.error_img > img{
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.err_{
  text-align: center;
  margin-top: 20px;
}
.err_ > h2{
  font-size: 50px;
}
.err_ > p{
  font-size: 20px;
}
.err_btn{
  display: block;
 outline: none;
 border: none;
 padding: 10px 30px;
 border-radius: 5px; 
 background-color: #ff003c;
 color: #fff;
 transition: all 0.2s ease-in-out;
 margin: 0 auto;
}
.err_btn:hover{
  background-color: #db0f3e;
}
.not_found{
  position: absolute;
  width: 70%;
  height: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  /* background-color: #fff; */
}
#err_msg_cont{
  width: 50%;
  margin: 0 auto;
  padding: 10px 0;
}