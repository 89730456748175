/* Page Layout */
.page-container {
  min-height: 100vh;
  padding: 2.5rem;
}

.content-wrapper {
  max-width: 44rem;
  margin: 0 auto;
}

/* Card Styles */
.card {
  background-color: white;
  border-radius: 0.75rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  overflow: hidden;
}

.card-header {
  padding: 1.5rem;
  border-bottom: 1px solid #e5e7eb;
}

.header-content {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.header-icon {
  color: #3b82f6;
}

.header-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #1f2937;
}

.card-content {
  padding: 1.5rem;
}

/* Sections Grid */
.sections-grid {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  margin-bottom: 1.5rem;
}

/* Key Section Styles */
.key-section {
  background-color: white;
  border-radius: 0.5rem;
  padding: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.key-section.active {
  border: 2px solid #3b82f6;
}
.key-section.inactive {
  border: 2px solid #f63b3b;
}
.key-section.disabled {
  border: 1px solid #e5e7eb;
  opacity: 0.75;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.section-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1f2937;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.status-badge {
  font-size: 0.875rem;
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
}

.status-badge.active {
  background-color: #dbeafe;
  color: #1e40af;
}
.status-badge.inactive {
  background-color: #ffc8c8;
  color: #ff3f3f;
}
.status-badge.disabled {
  background-color: #f3f4f6;
  color: #6b7280;
}

/* Input Styles */
.input-group {
  margin-bottom: 1rem;
  width: 100%;
}

.input-label {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  margin-top: 10px;
  margin-right: 10px;
  color: #374151;
  margin-bottom: 0.5rem;
}

.input-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: #f9fafb;
  width: 100%;
  padding: 0.75rem;
  border-radius: 0.375rem;
}

.input-icon {
  color: #9ca3af;
}

.input-field {
  flex: 1;
  background: transparent;
  border: none;
  font-family: monospace;
  font-size: 0.875rem;
  color: #374151;
  width: 100%;
}

.input-field:focus {
  outline: none;
}
.mode-container {
  width: 100% !important;
}
.input-field::placeholder {
  color: #9ca3af;
}
.key-section .card {
  width: 100% !important;
}
.input-field:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

/* Button Styles */
.button-container {
  display: flex;
  justify-content: center;
}

.save-button {
  padding: 0.75rem 1.5rem;
  background-color: #000;
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.save-button:hover {
  background-color: #635bff;
}

/* Confirm Alert Styles */
.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.7);
}

.react-confirm-alert {
  background: white;
  border-radius: 0.5rem;
  padding: 2rem;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.react-confirm-alert-button-group {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;
}

.react-confirm-alert-button-group button {
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  font-weight: 500;
  transition: all 0.2s;
}

.react-confirm-alert-button-group button:first-child {
  background-color: #3b82f6;
  color: white;
}

.react-confirm-alert-button-group button:first-child:hover {
  background-color: #2563eb;
}

.react-confirm-alert-button-group button:last-child {
  background-color: #e5e7eb;
  color: #374151;
}

.react-confirm-alert-button-group button:last-child:hover {
  background-color: #d1d5db;
}
/* Switch styles */
.mode-switch-container {
  display: flex;
  align-items: center;
  float: right;
  margin-bottom: 20px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  border-radius: 50%;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: #4caf50;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.mode-label {
  margin-left: 10px;
  font-size: 16px;
}
