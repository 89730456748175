.contract_page_123 {
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 2rem;
  font-family: system-ui, -apple-system, sans-serif;
}

.contract_container_456 {
  max-width: 800px;
  margin: 0 auto;
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.contract_container_456-2 {
  max-width: 800px;
  margin: 0 auto;
  background: transparent;
  padding-bottom: 50px;
}
.contract_title_789 {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin-bottom: 0.5rem;
}

.contract_subtitle_101 {
  text-align: center;
  color: #666;
  margin-bottom: 2rem;
  font-size: 14px;
}

.contract_section_202 {
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #eee;
}

.section_title_303 {
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.company_details_404 {
  font-size: 2rem;
}

.field_group_505 {
  margin-bottom: 1rem;
  min-height: 40px;
}

.field_group_505 label {
  /* display: block; */
  font-size: 14px;
  text-align: left;
  color: #666;
  margin-bottom: 0.5rem;
}

.static_text_606 {
  padding: 0.5rem;
  background: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #333;
}

.input_field_707 {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.input_field_707:focus {
  outline: none;
  border-color: #666;
}

.function_details_grid_808 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.payment_details_909 {
  background: #f8f8f8;
  padding: 1rem;
  border-radius: 4px;
}

.payment_row_1010 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  font-size: 14px;
}

.total_1111 {
  font-weight: 600;
  border-top: 1px solid #ddd;
  padding-top: 0.5rem;
  margin-top: 0.5rem;
}

.deposit_1212 {
  color: #0066cc;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

.terms_container_1313 {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.terms_content_1414 {
  font-size: 14px;
  color: #444;
}

.terms_content_1414 h3 {
  font-size: 14px;
  font-weight: 600;
  margin: 1rem 0 0.5rem;
}

.terms_content_1414 p {
  margin-bottom: 1rem;
  line-height: 1.4;
}

.checkboxes_1515 {
  margin-bottom: 2rem;
}

.checkbox_container_1616 {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.5rem;
  font-size: 14px;
}

.checkbox_container_1616 input {
  margin-right: 0.5rem;
  margin-top: 0.2rem;
}

.deposit_section_1717 {
  background: #f8f8f8;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 2rem;
}

.payment_gateway_1818 {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 1rem;
  margin-top: 1rem;
  text-align: center;
  color: #666;
}

.button_container_1919 {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.cancel_button_2020 {
  padding: 0.5rem 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #fff;
  color: #666;
  font-size: 14px;
  cursor: pointer;
}

.confirm_button_2121 {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  background: #0066cc;
  color: white;
  font-size: 14px;
  cursor: pointer;
}

.confirm_button_2121:hover {
  background: #0052a3;
}

.cancel_button_2020:hover {
  background: #f5f5f5;
}
/* Global styles */
:root {
  --primary-color: #3b82f6;
  --primary-hover: #2563eb;
  --error-color: #ef4444;
  --success-color: #22c55e;
  --border-color: #e5e7eb;
  --text-primary: #1f2937;
  --text-secondary: #6b7280;
  --bg-secondary: #f9fafb;
}

/* Layout */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1.5rem;
}

/* Card Styles */
.card {
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1);
  margin-bottom: 1.5rem;
}

.card-header {
  padding: 1.25rem 1.5rem;
  border-bottom: 1px solid var(--border-color);
}

.card-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text-primary);
}

.card-content {
  padding: 1.5rem;
}

/* Form Elements */
.input-group {
  margin-bottom: 1rem;
}

.label {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--text-primary);
  margin-bottom: 0.5rem;
}

.input {
  width: 100%;
  padding: 0.5rem 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: 0.375rem;
  font-size: 0.875rem;
}

.input:focus {
  outline: none;
  border-color: var(--primary-color);
  ring: 2px var(--primary-color);
}

/* Buttons */
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: all 0.2s;
}

.btn-primary {
  background-color: var(--primary-color);
  color: white;
}

.btn-primary:hover {
  background-color: var(--primary-hover);
}

.btn-secondary {
  background-color: white;
  border: 1px solid var(--border-color);
}

.btn-secondary:hover {
  background-color: var(--bg-secondary);
}

.btn-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Payment Form Specific Styles */
.payment-element {
  padding: 1rem;
  border: 1px solid var(--border-color);
  border-radius: 0.5rem;
}

.stripe-element {
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: 0.375rem;
  background-color: white;
}

/* Status Messages */
.alert {
  padding: 1rem;
  border-radius: 0.375rem;
  margin-bottom: 1rem;
}

.alert-success {
  background-color: #f0fdf4;
  border: 1px solid #bbf7d0;
  color: #166534;
}

.alert-error {
  background-color: #fef2f2;
  border: 1px solid #fecaca;
  color: #991b1b;
}

.alert-info {
  background-color: #f0f9ff;
  border: 1px solid #bae6fd;
  color: #075985;
}

/* Grid and Flex Utilities */
.grid {
  display: grid;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.gap-4 {
  gap: 1rem;
}

.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

/* Spacing Utilities */
.mt-2 {
  margin-top: 0.5rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.p-4 {
  padding: 1rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

/* Text Utilities */
.text-center {
  text-align: center;
}
.text-sm {
  font-size: 0.875rem;
}
.font-medium {
  font-weight: 500;
}
.font-semibold {
  font-weight: 600;
}
.text-gray-600 {
  color: var(--text-secondary);
}

/* Border Utilities */
.border-t {
  border-top: 1px solid var(--border-color);
}

.rounded-md {
  border-radius: 0.375rem;
}
